import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { SwUpdate } from '@angular/service-worker';
import { BehaviorSubject, Subject, fromEvent, Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { CookieService } from 'ngx-cookie-service';

import { bitfToTranslate } from '@bitf/utils/bitf-translate.utils';

import { BITF_CONFIGS } from '@configs';
import { environment } from '@env/environment';
import { EBitfOnlineStates, EBitfCloseEventStatus } from '@enums';
import { IBitfCloseEvent } from '@interfaces';
import { StoreService, DialogsService } from '@services';
import { CONSTANTS } from '@constants';

// PWA REF:
// https://developers.google.com/web/updates/2018/06/a2hs-updates
// PWA debugger infos
// https://developers.google.com/web/ilt/pwa/tools-for-pwa-developers

@Injectable({
  providedIn: 'root',
})
export class BitfPwaService {
  onlineStatus: EBitfOnlineStates = EBitfOnlineStates.ONLINE;
  swUpdateNotified = false;
  onlineStatus$: Subject<EBitfOnlineStates> = new Subject<EBitfOnlineStates>();
  swUpdate$: Subject<any> = new Subject<any>();
  onAppInstalled$: Observable<Event>;
  beforeInstallPrompt$ = new BehaviorSubject<Event>(undefined);
  beforeInstallPromptEvent: any;

  constructor(
    public swUpdate: SwUpdate,
    private http: HttpClient,
    private storeService: StoreService,
    private cookieService: CookieService,
    public dialogsService: DialogsService
  ) {}

  init() {
    if (environment.registerServiceWorker) {
      this.detectStandaloneMode();
      this.initOnAppInstalled();
    }
  }

  /**
   * Inizialize the manifest tag (creating a new one if don't exist)
   * and set the href attribute created from the param
   * @param string manifest path of the manifest file to load
   */
  initManifest(manifest = '/manifest.json'): void {
    // const blob = new Blob([JSON.stringify(manifest)], { type: 'application/json' });
    // const manifestURL = URL.createObjectURL(blob);
    const manifestTag = document.querySelector('head > link[rel="manifest"]');
    if (manifestTag) {
      manifestTag.setAttribute('href', manifest);
    }
  }

  // Install app management
  initBeforeInstallPrompt() {
    if (environment.registerServiceWorker) {
      fromEvent(window, 'beforeinstallprompt')
        .pipe(
          tap(event => {
            event.preventDefault();
            const shoudEmitEvent =
              !this.cookieService.get(`${environment.appName}-beforeInstallPromptNotAccepted`) &&
              !this.cookieService.get(`${environment.appName}-pwaInstalled`);
            if (shoudEmitEvent) {
              this.beforeInstallPrompt$.next(event);
              this.beforeInstallPromptEvent = event;
            } else {
              this.beforeInstallPrompt$.next(undefined);
              this.beforeInstallPromptEvent = undefined;
            }
          })
        )
        .subscribe();
    }
  }

  checkForBeforeInstallPromptEvent() {
    this.beforeInstallPrompt$.subscribe(event => {
      if (event) {
        const expireDate = new Date(
          Date.now() + BITF_CONFIGS.pwa.serviceWorker.askAgainAfterNDays * 24 * 60 * 60 * 1000
        );
        this.showDefaultInstallPromptDialog(event, expireDate);
      }
    });
  }

  showDefaultInstallPromptDialog(event, askAfterDate: Date) {
    if (!event) {
      return;
    }
    const dialogRef = this.dialogsService.dialog.open(CONSTANTS.okCancelDialogComponent, {
      width: 'auto',
      maxWidth: '90%',
      disableClose: true,
      data: {
        title: bitfToTranslate('BITF.LABEL.INFO'),
        message: bitfToTranslate('BITF.PWA.INFORM_ABOUT_INSTALL'),
        okText: bitfToTranslate('BITF.LABEL.OK'),
      },
    });
    dialogRef.afterClosed().subscribe(() => {
      event.prompt();
      // Wait for the user to respond to the native prompt
      event.userChoice.then(choiceResult => {
        if (choiceResult.outcome !== 'accepted') {
          // NOTE: in case the user clicks no we'll defer the dialog to askAfterDate date
          this.cookieService.set(
            `${environment.appName}-beforeInstallPromptNotAccepted`,
            'notAccepted',
            askAfterDate
          );

          // NOTE we've to send a next with undefined also if the user accept otherwise we'll prompt again to
          // downlaod
          this.beforeInstallPrompt$.next(undefined);
          this.beforeInstallPromptEvent = undefined;

          return;
        }
        // NOTE: this is to prevent to ask again to install the app meanwile the app is installed in the device
        // Bypass the beforeinstallprompt event for 20 seconds
        this.cookieService.set(
          `${environment.appName}-pwaInstalled`,
          'notAccepted',
          new Date(Date.now() + 20 * 1000)
        );
        this.beforeInstallPrompt$.next(undefined);
        this.beforeInstallPromptEvent = undefined;
      });
    });
  }

  // Push updates management

  initSwUpdate() {
    if (environment.registerServiceWorker) {
      this.swUpdate.available.subscribe(data => {
        this.swUpdate.activateUpdate().then(() => {
          if (!this.swUpdateNotified) {
            this.swUpdate$.next(data);
          }
          this.swUpdateNotified = true;
        });
      });

      this.swUpdate.checkForUpdate();
      setInterval(() => {
        this.swUpdate.checkForUpdate();
      }, BITF_CONFIGS.pwa.serviceWorker.checkUpdateInterval);
    }
  }

  handleSwUpdate() {
    this.swUpdate$.subscribe(() => {
      this.showDefaultSwUpdateDialog();
    });
  }

  showDefaultSwUpdateDialog() {
    const dialogOptions = {
      width: 'auto',
      maxWidth: '90%',
      disableClose: true,
      data: {
        title: bitfToTranslate('BITF.LABEL.INFO'),
        message: bitfToTranslate('BITF.APP_UPDATE.NEW_APP_AVAILABLE_PROMPT'),
        cancelText: bitfToTranslate('BITF.LABEL.NO'),
        okText: bitfToTranslate('BITF.LABEL.YES'),
      },
    };
    // We've to force PWA on safari to refresh because id the user click no, next time he'll open
    // the app, the app will not refresh, so the user is stuck to the old version
    // NOTE: it seems to work now TOBE REMOVED
    // if (this.storeService.store.browser.isSafariMobile && this.storeService.store.isStandAlone) {
    //   Object.assign(dialogOptions.data, {
    //     message: bitfToTranslate('BITF.APP_UPDATE.FORCE_NEW_APP_UPDATE_PROMPT'),
    //     okText: bitfToTranslate('BITF.LABEL.OK'),
    //     cancelText: undefined,
    //   });
    // }
    const dialogRef = this.dialogsService.dialog.open(CONSTANTS.okCancelDialogComponent, dialogOptions);
    dialogRef.afterClosed().subscribe((result: IBitfCloseEvent<void>) => {
      if (result.status === EBitfCloseEventStatus.OK) {
        window.location.reload();
      }
    });
  }

  initOnlineChecker() {
    // REF: old version
    // interval(5000)
    //   .pipe(
    //     switchMap(() => {
    //       return this.http.get(`assets/pwa/online-checker/ping.json?v=${new Date().getTime()}`).pipe(
    //         map(() => {
    //           return EBitfOnlineStates.ONLINE;
    //         }),
    //         catchError(() => of(EBitfOnlineStates.OFFLINE))
    //       );
    //     })
    //   )
    //   .subscribe((status: EBitfOnlineStates) => this.switchStatus(status));
    this.handleStateChange();
    window.addEventListener('online', this.handleStateChange.bind(this));
    window.addEventListener('offline', this.handleStateChange.bind(this));
  }

  private handleStateChange() {
    if (navigator.onLine) {
      this.switchStatus(EBitfOnlineStates.ONLINE);
    } else {
      this.switchStatus(EBitfOnlineStates.OFFLINE);
    }
  }

  private switchStatus(currentStatus: EBitfOnlineStates) {
    if (this.onlineStatus !== currentStatus) {
      this.onlineStatus = currentStatus;
      this.onlineStatus$.next(this.onlineStatus);
    }
  }

  private initOnAppInstalled() {
    // https://developer.mozilla.org/en-US/docs/Web/API/Window/onappinstalled
    this.onAppInstalled$ = fromEvent(window, 'onappinstalled');
  }

  private detectStandaloneMode() {
    if (window.matchMedia('(display-mode: standalone)').matches || window.navigator['standalone'] === true) {
      this.storeService.store.isStandAlone = true;
    }
  }
}
