<ng-container *ngIf="formItem && formItem.isVisible"
  [ngSwitch]="formItem.type">

  <!-- Input -->
  <mat-form-field *ngSwitchCase="'text'"
    [appearance]="componentConfig.appearance || 'outline'"
    [ngClass]="matFormFieldClass ? matFormFieldClass : 'w-100'"
    [disabled]="formItem.isDisabled">
    <mat-label>{{ formItem.label }}</mat-label>
    <input matInput
      [readonly]="formItem.isReadOnly || !formItem.isEditable"
      *bitfUiRoleManager="componentConfig.uiRole"
      type="text"
      placeholder="{{ formItem.label }}"
      [formControl]="formControl" />
  </mat-form-field>


  <mat-form-field *ngSwitchCase="'number'"
    [appearance]="componentConfig.appearance || 'outline'"
    [ngClass]="matFormFieldClass ? matFormFieldClass : 'w-100'"
    [disabled]="formItem.isDisabled">
    <mat-label>{{ formItem.label }}</mat-label>
    <input matInput
      *bitfUiRoleManager="componentConfig.uiRole"
      type="text"
      [readonly]="formItem.isReadOnly || !formItem.isEditable"
      [textMask]="{mask: numberMask}"
      placeholder="{{ formItem.label }}"
      [formControl]="formControl" />
  </mat-form-field>

  <!-- Autocomplete -->
  <mat-form-field [ngClass]="matFormFieldClass ? matFormFieldClass : 'w-100'"
    [appearance]="componentConfig.appearance || 'outline'"
    *ngSwitchCase="'autocomplete'"
    [disabled]="formItem.isDisabled">
    <mat-label>{{ formItem.label }}</mat-label>
    <input matInput
      [readonly]="formItem.isReadOnly || !formItem.isEditable"
      placeholder="{{formItem.label}}"
      *bitfUiRoleManager="componentConfig.uiRole"
      [matAutocomplete]="auto"
      [formControl]="formControl">
    <mat-icon matSuffix
      *ngIf="!showLoader">search</mat-icon>
    <mat-spinner matSuffix
      *ngIf="showLoader"
      [diameter]="20"></mat-spinner>
    <mat-autocomplete #auto="matAutocomplete"
      [displayWith]="displayFn.bind(this)"
      (optionSelected)="onSelectionChange($event)">
      <mat-option *ngFor="let data of componentConfig.autocompleteData"
        [value]="componentConfig?.useObject ? data : data?.id">
        {{data[componentConfig.autocompleteDataKeyLabel || 'label']}}
      </mat-option>
    </mat-autocomplete>
  </mat-form-field>


  <!-- Date -->
  <mat-form-field *ngSwitchCase="'date'"
    [appearance]="componentConfig.appearance || 'outline'"
    [ngClass]="matFormFieldClass ? matFormFieldClass : 'w-100'">
    <mat-label>{{ formItem.label }}</mat-label>
    <input matInput
      *bitfUiRoleManager="componentConfig.uiRole"
      [matDatepicker]="pickerDate"
      placeholder="{{ formItem.label }}"
      [formControl]="formControl"
      [readonly]="true"
      (dateChange)="onDateChange()"
      (click)="toggleDatePicker()" />
    <mat-datepicker-toggle matSuffix
      *bitfUiRoleManager="hiddenUIRole"
      [for]="pickerDate"></mat-datepicker-toggle>
    <mat-datepicker #pickerDate></mat-datepicker>
  </mat-form-field>

  <mat-form-field *ngSwitchCase="'date-range'"
    [ngClass]="matFormFieldClass ? matFormFieldClass : 'w-100'"
    [appearance]="componentConfig.appearance || 'outline'">
    <mat-label>{{ formItem.label }}</mat-label>
    <mat-date-range-input [rangePicker]="dateRangePicker">
      <input matStartDate
        (dateChange)="onDateChange()"
        placeholder="{{ formItem.label }}">
      <input matEndDate
        (dateChange)="onDateChange()"
        placeholder="{{ formItem.label }}">
    </mat-date-range-input>
    <mat-datepicker-toggle matSuffix
      [for]="dateRangePicker"></mat-datepicker-toggle>
    <mat-date-range-picker #dateRangePicker></mat-date-range-picker>
  </mat-form-field>

  <!-- Textarea -->
  <mat-form-field *ngSwitchCase="'textarea'"
    [appearance]="componentConfig.appearance || 'outline'"
    [ngClass]="matFormFieldClass ? matFormFieldClass : 'w-100'"
    [disabled]="formItem.isDisabled">
    <mat-label>{{ formItem.label }}</mat-label>
    <textarea matInput
      cdkTextareaAutosize
      [readonly]="formItem.isReadOnly || !formItem.isEditable"
      #autosize="cdkTextareaAutosize"
      cdkAutosizeMinRows="2"
      cdkAutosizeMaxRows="10"
      [formControl]="formControl"
      *bitfUiRoleManager="componentConfig.uiRole"
      placeholder="{{ formItem.label }}"></textarea>
  </mat-form-field>

  <!-- Checkbox -->
  <div *ngSwitchCase="'checkbox'">
    <mat-checkbox [disabled]="formItem.isDisabled || !canAction()"
      color="primary"
      class="text-left"
      [formControl]="formControl">
      <span class="label-wrap">{{ formItem.label }}</span></mat-checkbox>
  </div>

  <!-- Slide Toggle -->
  <div *ngSwitchCase="'slide-toggle'"
    class="mb-3">
    <mat-slide-toggle [disabled]="formItem.isDisabled || !canAction()"
      color="primary"
      [formControl]="formControl">{{ formItem.label }}</mat-slide-toggle>
  </div>
</ng-container>


<ng-template #inputTextNumeric>

</ng-template>