import { Component, OnInit, Input } from '@angular/core';
import { YieldData } from '@web/core/models';

@Component({
  selector: 'mra-yield-matrix',
  templateUrl: './yield-matrix.component.html',
  styleUrls: ['./yield-matrix.component.scss'],
})
export class YieldMatrixComponent implements OnInit {
  @Input() yieldData: YieldData;
  constructor() {}

  ngOnInit() {}
}
