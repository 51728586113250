import { Component } from '@angular/core';
import { CommonLayout } from '@common/core/components/layout/common-layout.component';

@Component({
  selector: 'app-mobile-layout',
  templateUrl: './mobile-layout.component.html',
  styleUrls: ['./mobile-layout.component.scss'],
})
export class MobileLayoutComponent extends CommonLayout {
  updateLayout() {
    // const data = this.route.snapshot.firstChild.data;
  }
}
