<mat-sidenav-container class="h-100-print-auto">
  <mat-sidenav #sidenav>
    <ng-container #sidenavContent></ng-container>
  </mat-sidenav>
  <mat-sidenav-content>
    <mra-main-header [drawer]="drawer"></mra-main-header>
    <div class="h-100">
      <mat-drawer-container
        [hasBackdrop]="!storeService.store.activeBreakpoints.isLarge && !storeService.store.activeBreakpoints.isXLarge"
        class="h-100">
        <mat-drawer #drawer
          class="d-print-none"
          [mode]="storeService.store.activeBreakpoints.isLarge || storeService.store.activeBreakpoints.isXLarge  ? 'side': 'over'"
          [opened]="storeService.store.activeBreakpoints.isLarge || storeService.store.activeBreakpoints.isXLarge">
          <mra-sidenav-menu></mra-sidenav-menu>
        </mat-drawer>
        <mat-drawer-content class="h-100">
          <router-outlet></router-outlet>
        </mat-drawer-content>
      </mat-drawer-container>
    </div>
  </mat-sidenav-content>
</mat-sidenav-container>
<bitf-loader></bitf-loader>