import { Component, OnInit, OnDestroy } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Router, NavigationEnd } from '@angular/router';
import { Subscription } from 'rxjs';
import { StoreService } from '@web/core/services';

@Component({
  selector: 'mra-sidenav-menu',
  templateUrl: './sidenav-menu.component.html',
  styleUrls: ['./sidenav-menu.component.scss'],
})
export class SidenavMenuComponent implements OnInit, OnDestroy {
  private subscription: Subscription;

  menu: {
    icon: string;
    label: string;
    routerLink: string[];
  }[] = [];

  constructor(
    private router: Router,
    private translateService: TranslateService,
    public storeService: StoreService
  ) {}

  ngOnInit(): void {
    this.subscription = this.router.events.subscribe(val => {
      if (val instanceof NavigationEnd) {
        this.updateMenu();
      }
    });
    this.updateMenu();
  }

  private updateMenu() {
    if (this.router.url.indexOf('/admin') === 0) {
      this.menu = [
        {
          icon: 'dashboard',
          label: this.translateService.instant('COMMON.LABEL.DASHBOARD'),
          routerLink: ['admin/dashboard'],
        },
        {
          icon: 'store_mall_directory',
          label: this.translateService.instant('COMMON.LABEL.PHARMACIES_LIST'),
          routerLink: ['admin/pharmacies'],
        },
        {
          icon: 'settings',
          label: this.translateService.instant('COMMON.LABEL.SETTINGS'),
          routerLink: ['admin/surveys/draft'],
        },
      ];
    }
    if (this.router.url.indexOf('/owner') === 0) {
      const pharmacyId = this.storeService.store.user.pharmacyId;
      this.menu = [
        {
          icon: 'dashboard',
          label: this.translateService.instant('COMMON.LABEL.DASHBOARD'),
          routerLink: ['owner/dashboard'],
        },
        {
          icon: 'note',
          label: `${this.translateService.instant('COMMON.LABEL.ASSESSMENTS')} ${new Date().getFullYear()}`,
          routerLink: ['owner/assessments-management'],
        },
        {
          icon: 'list',
          label: this.translateService.instant('COMMON.LABEL.ASSESSMENTS_HISTORY'),
          routerLink: [`owner/pharmacies/${pharmacyId}/assessments`],
        },
        {
          icon: 'contacts',
          label: this.translateService.instant('COMMON.LABEL.REGISTRIES'),
          routerLink: [`owner/pharmacies/${pharmacyId}/registry`],
        },
      ];
    }
    if (this.router.url.indexOf('/supervisor') === 0) {
      this.menu = [
        {
          icon: 'dashboard',
          label: this.translateService.instant('COMMON.LABEL.DASHBOARD'),
          routerLink: ['supervisor/dashboard'],
        },
        {
          icon: 'store_mall_directory',
          label: this.translateService.instant('COMMON.LABEL.PHARMACIES_LIST'),
          routerLink: ['supervisor/pharmacies'],
        },
      ];
    }

    if (this.router.url.indexOf('/collaborator') === 0) {
      this.menu = [
        {
          icon: 'person',
          label: this.translateService.instant('COMMON.LABEL.MY_PROFILE'),
          routerLink: ['collaborator/profile'],
        },
      ];
    }
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
