import { Injectable, Injector } from '@angular/core';

import { BitfApiService } from '@bitf/services/api/bitf-api.service';

@Injectable({
  providedIn: 'root',
})
export class PharmaciesViewService extends BitfApiService {
  constructor(public injector: Injector) {
    // super('vw-pharmacies-with-stats', injector, environment.mockApiUrl);
    super('vw-pharmacies-with-stats', injector);
  }
}
