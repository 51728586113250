<div class="m-3 col-lg-12 col-md-16 col-sm-18 col-24">
    <div class="row d-flex justify-content-between mb-3">
        <div *ngFor="let i of assessmentValues">
            <button mat-button
                [disabled]="readOnly"
                class="btn-circle btn-xl"
                [ngClass]="{'btn-selected': score === i }"
                (click)="onScoreClick(i)">{{i}}</button>
        </div>
    </div>
    <div class="row d-flex justify-content-between">
        <div class="text-uppercase">{{'COMMON.LABEL.NEVER' | translate}}</div>
        <div class="col-lg-13 col-md-12 col-sm-10 col-10">
            <hr class="rating-line" />
        </div>
        <div class="text-uppercase">{{'COMMON.LABEL.ALWAYS' | translate}}</div>
    </div>
</div>