import { NgModule, ModuleWithProviders, CUSTOM_ELEMENTS_SCHEMA, LOCALE_ID } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CommonSharedModule } from '@common/shared/common-shared.module';
import { MATERIAL_MODULES, CDK_MODULES } from './material-modules';

// tslint:disable:max-line-length
import { BitfLoaderComponent } from '@bitf/core/components/ui/loader/bitf-loader.component';
import { BitfMatPaginatorComponent } from '@bitf/core/components/ui/paginator/material/bitf-mat-paginator.component';
import { BitfUiRoleManagerDirective } from '@common/libs/bitforce/directives/ui-role-manager/bitf-ui-role-manager.directive';
import { BitfTouchEventDirective } from '@common/libs/bitforce/directives/bitf-touch-events/bitf-touch-event.directive';
import { BitfMatSortComponent } from '@common/libs/bitforce/core/components/ui/sort/material/bitf-mat-sort.component';
import { BitfMatSearchComponent } from '@common/libs/bitforce/core/components/ui/search/material/bitf-mat-search.component';
import { BitfMatFormItemComponent } from '@common/libs/bitforce/core/components/ui/form-item/material/mat-form-item/bitf-mat-form-item.component';
import { BitfMatFormOptionComponent } from '@common/libs/bitforce/core/components/ui/form-item/material/mat-form-option/bitf-mat-form-option.component';
// tslint:enable:max-line-length

import { SidenavMenuComponent } from './components/sidenav-menu/sidenav-menu.component';
import { MainHeaderComponent } from './components/main-header/main-header.component';
import { GaugeComponent } from './components/gauge-chart/gauge-chart.component';
import { ChartModule } from 'angular2-chartjs';
// tslint:disable:max-line-length
import { QualitativeSkillsComponent } from './components/qualitative-skills/qualitative-skills.component';
import { QuantitativeSkillsComponent } from './components/quantitative-skills/quantitative-skills.component';
import { YieldMatrixComponent } from './components/yield-matrix/yield-matrix.component';
import { RadarChartComponent } from './components/radar-chart/radar-chart.component';
import { DoughnutChartComponent } from './components/doughnut-chart/doughnut-chart.component';
import { QuestionaryCardComponent } from './components/questionary-card/questionary-card.component';
import { QuestionaryRatingComponent } from './components/questionary-rating/questionary-rating.component';
import { QuestionCardComponent } from './components/question-card/question-card.component';
import { BitfDynamicLocaleService } from '@common/libs/bitforce/core/services/locale/bitf-dynamic-locale.service';
import { AssessmentsListComponent } from './components/assessments-list/assessments-list.component';
// tslint:enable:max-line-length

const SHARED_MODULES = [CommonSharedModule, RouterModule, ChartModule, MATERIAL_MODULES, CDK_MODULES];
const SHARED_COMPONENTS = [
  BitfLoaderComponent,
  BitfMatPaginatorComponent,
  BitfUiRoleManagerDirective,
  BitfTouchEventDirective,
  SidenavMenuComponent,
  MainHeaderComponent,
  GaugeComponent,
  BitfMatSortComponent,
  BitfMatSearchComponent,
  BitfMatFormItemComponent,
  BitfMatFormOptionComponent,
  QualitativeSkillsComponent,
  QuantitativeSkillsComponent,
  YieldMatrixComponent,
  RadarChartComponent,
  DoughnutChartComponent,
  QuestionaryCardComponent,
  QuestionaryRatingComponent,
  QuestionCardComponent,
  AssessmentsListComponent,
];
const SHARED_DIRECTIVES = [];

@NgModule({
  imports: [...SHARED_MODULES],
  exports: [...SHARED_MODULES, ...SHARED_COMPONENTS, ...SHARED_DIRECTIVES],
  declarations: [...SHARED_COMPONENTS, ...SHARED_DIRECTIVES],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class SharedModule {
  static forRoot(): ModuleWithProviders<SharedModule> {
    return {
      ngModule: SharedModule,
      providers: [
        {
          provide: LOCALE_ID,
          useFactory: (dynamicLocale: BitfDynamicLocaleService) => dynamicLocale.defaultLocale.code,
          deps: [BitfDynamicLocaleService],
        },
      ],
    };
  }
}
