const apiUrl = `https://apmra-dev.sintrasviluppo.it/api/`;

export const environment = {
  production: false,
  name: 'remote-development',
  appName: 'Aboca MRA',
  storageVersion: '1.0.0',
  apiUrl,
  authInterceptorUrls: [apiUrl],
  mockApiUrl: 'https://db96ddff-086c-4a97-918c-65d0b41068b2.mock.pstmn.io/api/',
  logUrl: '',
  sockets: { url: '' },
  httpRetryConfig: {
    enabled: true,
    verbs: {
      get: { enabled: true, timeout: 12000, maxRetries: 2, retryForStatusesCodes: [500] },
      post: { enabled: false, timeout: null, maxRetries: 1, retryForStatusesCodes: [500] },
      patch: { enabled: false, timeout: null, maxRetries: 1, retryForStatusesCodes: [500] },
      put: { enabled: false, timeout: null, maxRetries: 1, retryForStatusesCodes: [500] },
      delete: { enabled: false, timeout: null, maxRetries: 1, retryForStatusesCodes: [500] },
    },
  },
  loggerConfig: {
    enabled: true,
    maxLogRentention: 20,
    logHttpErrorsWithStatusesCodes: ['0', '4XX', '5XX'],
    logDecoratedExceptions: true,
    logExceptions: true,
    saveLogMessagesInterval: 30000,
    logUrl: 'https://logs.logdna.com/logs/ingest?apikey=bf8cf553dba4a1e882fa09491aaed2c7',
    sendLogs: false,
    sendRequestBody: false,
    sendQueryParams: false,
  },
  tagManager: {
    enabled: false,
    apiKey: '',
  },
  registerServiceWorker: false,
};
