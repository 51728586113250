import { SuperModel } from './super-model.model';
import { Roles } from '@web/enums';

export class Weight extends SuperModel {
  subRoleId: number;
  subRole: string;
  weight: number;

  constructor(data = {}) {
    super(data);
    this.subRole = Roles[this.subRoleId];
  }

  get serialised() {
    return {
      id: this.id,
      subRoleId: this.subRoleId,
      weight: this.weight,
    };
  }
}
