import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpRequest, HttpErrorResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { tap, take } from 'rxjs/operators';

import { bitfIsCallingApi } from '@bitf/utils/bitf-urls.utils';

import { StoreService, DialogsService, UiMessagesListenerService, AuthService } from '@services';
import { BITF_CONFIGS } from '@configs';
import { EBitfInterceptors, eStoreActions, EBitfAuthState } from '@enums';
import { environment } from '@env/environment';

@Injectable()
export class BitfApiErrorsInterceptor {
  constructor(
    public dialogsService: DialogsService,
    private storeService: StoreService,
    private uiMessagesListenerService: UiMessagesListenerService,
    private authService: AuthService
  ) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (!bitfIsCallingApi(environment, req)) {
      return next.handle(req);
    }

    if (req.headers.has(EBitfInterceptors.BITF_API_ERRORS_INTERCEPTOR)) {
      const newReq = req.clone({
        headers: req.headers.delete(EBitfInterceptors.BITF_API_ERRORS_INTERCEPTOR),
      });
      return next.handle(newReq);
    }

    return next.handle(req).pipe(
      tap(
        () => {},
        errorResponse => {
          if (!(errorResponse instanceof HttpErrorResponse)) {
            return;
          }
          // NOTE we've injected the body prop in the HttpErrorResponse object when we create the envelope
          const uiMessages =
            errorResponse['body'] &&
            errorResponse['body'].metadata &&
            errorResponse['body'].metadata.uiMessages;
          // If there is a uiMessages, this error will be handled by BitfApiUiMessagesInterceptor
          if (uiMessages && uiMessages.length) {
            return;
          }
          const statusCode = String(errorResponse.status);
          const statusCodes = [statusCode, `${statusCode.split('')[0]}XX`];
          for (const sC of statusCodes) {
            if (BITF_CONFIGS.apiErrorsInterceptorService.interceptHttpResponsesWithCode.includes(sC)) {
              // NOTE: if is a 401 error not authorized / session expired we've to wait that the authService
              // performs a renewToken and then check again if there is a token valid
              if (statusCode === '401') {
                this.shouldShowSessionExpiredMessage().then(
                  () => {
                    this.showMessage(sC, statusCode);
                  },
                  () => {}
                );
              } else {
                this.showMessage(sC, statusCode);
              }
              break;
            }
          }
        }
      )
    );
  }

  private showMessage(statusCode: string, originalStatusCode: string) {
    this.storeService.store.uiMessages$.next({
      type: 'BitfApiErrorsInterceptor',
      strategy: statusCode,
      payload: originalStatusCode,
    });
  }

  private async shouldShowSessionExpiredMessage() {
    // NOTE we've to wait for a renewToken call because this interceptor runs
    // before the retryWhen inside the fetch in super api
    return new Promise((success, error) => {
      if (this.uiMessagesListenerService.isSessionExpiredNotified) {
        error('showSessionExpiredMessage already shown');
        return;
      }
      this.storeService
        .selectStore(eStoreActions.SET_AUTH_TOKEN)
        .pipe(take(1))
        .subscribe(() => {
          if (this.authService.isTokenExpired()) {
            this.uiMessagesListenerService.isSessionExpiredNotified = true;
            // NOTE: we are sending a NOT_AUTHORISED event here because this comes from a 401 response
            this.authService.authState$.next(EBitfAuthState.NOT_AUTHORISED);
            success();
            return;
          }
          error('token have been renewed');
        });
    });
  }
}
