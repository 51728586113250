import { IBitfLanguage } from '@interfaces';

/**
 * General Storage model with properties needed from the Bitf core services.
 */
export class BitfStorage {
  /**
   * Selected language, used by BitfDynamicLocaleService
   */
  selectedLanguage?: IBitfLanguage;
  /** Redirect url used by auth system */
  redirectUrl?: string;

  constructor(data: Partial<BitfStorage> = {}) {
    Object.assign(this, data);
  }
}
