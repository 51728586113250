import { Injectable, Injector } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { BitfAuthGuard } from '@bitf/core/services/guards/bitf-auth.guard';
import { isObservable, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard extends BitfAuthGuard {
  constructor(protected injector: Injector) {
    super(injector);
  }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    let canActivate = super.canActivate(next, state);
    const onCanActivate = response => response && this.checkRoleAndRedirectIfNeeded(next);

    if (isObservable(canActivate)) {
      canActivate = canActivate.pipe(map(onCanActivate));
    } else {
      canActivate = Promise.resolve(canActivate).then(onCanActivate);
    }

    return canActivate;
  }

  private checkRoleAndRedirectIfNeeded(next: ActivatedRouteSnapshot) {
    const canActivate = this.checkRole(next);
    if (!canActivate) {
      const user = this.storeService.store.user;
      this.router.navigate(this.routeFromProfile(user.profileId));
    }

    return canActivate;
  }

  private checkRole(next: ActivatedRouteSnapshot) {
    const user = this.storeService.store.user;
    if (!user) {
      return false;
    }

    return user.eProfile === next.data.eProfile;
  }

  private routeFromProfile(profileId: string): string[] {
    const routes = {
      Amministratore: ['/admin'],
      SuperTitolare: ['/owner'],
      Titolare: ['/owner'],
      AbocaAdmin: ['/supervisor'],
      TDF: ['/supervisor'],
      Collaboratore: ['/collaborator'],
      CollaboratoreADV: ['/collaborator'],
    };

    return routes[profileId] || ['/sign-in'];
  }
}
