export enum EApiCallStateNames {
  ADMIN_PHARMACIES_LIST = 'ADMIN_PHARMACIES_LIST',
  HISTORICAL_ASSESSMENTS = 'HISTORICAL_ASSESSMENTS',
  GROUPED_ASSESSMENTS = 'GROUPED_ASSESSMENTS',
  REGISTRIES = 'REGISTRIES',
}

export enum EApiRequestPartKeys {
  SEARCH = 'SEARCH',
  PAGINATION = 'PAGINATION',
  SORTING = 'SORTING',
  FILTERS = 'FILTERS',
  SORT_ASSESSMENTS_LIST_ID = 'SORT_ASSESSMENTS_LIST_ID',
  SORT_ASSESSMENTS_LIST_NAME = 'SORT_ASSESSMENTS_LIST_NAME',
  SORT_ASSESSMENTS_LIST_ROLE = 'SORT_ASSESSMENTS_LIST_ROLE',
  SORT_ASSESSMENTS_LIST_TOT = 'SORT_ASSESSMENTS_LIST_TOT',
  SORT_ASSESSMENTS_LIST_LATEST_DATE = 'SORT_ASSESSMENTS_LIST_LATEST_DATE',
  SORT_ASSESSMENTS_LIST_LATEST_SCORE = 'SORT_ASSESSMENTS_LIST_LATEST_SCORE',
  SORT_ASSESSMENTS_USER_DELETED = 'SORT_ASSESSMENTS_USER_DELETED',
  SORT_PHARMACIES_LIST_NAME = 'SORT_PHARMACIES_LIST_NAME',
  SORT_PHARMACIES_LIST_PDC_CODE = 'SORT_PHARMACIES_LIST_PDC_CODE',
  SORT_PHARMACIES_LIST_KAM_CODE = 'SORT_PHARMACIES_LIST_KAM_CODE',
  SORT_PHARMACIES_LIST_TDF_NAME = 'SORT_PHARMACIES_LIST_TDF_NAME',
  SORT_PHARMACIES_LIST_EMP_COUNT = 'SORT_PHARMACIES_LIST_EMP_COUNT',
  SORT_PHARMACIES_LIST_ASSESSMENTS_COUNT = 'SORT_PHARMACIES_LIST_ASSESSMENTS_COUNT',
  SORT_PHARMACIES_LIST_LATEST_ASSESSMENT_DATE = 'SORT_PHARMACIES_LIST_LATEST_ASSESSMENT_DATE',
  SORT_PHARMACIES_LIST_ACTIVE = 'SORT_PHARMACIES_LIST_ACTIVE',
  SORT_HISTORICAL_ASSESSMENTS_ID = 'SORT_HISTORICAL_ASSESSMENTS_ID',
  SORT_HISTORICAL_ASSESSMENTS_NAME = 'SORT_HISTORICAL_ASSESSMENTS_NAME',
  SORT_HISTORICAL_ASSESSMENTS_ROLE = 'SORT_HISTORICAL_ASSESSMENTS_ROLE',
  SORT_HISTORICAL_ASSESSMENTS_DATE = 'SORT_HISTORICAL_ASSESSMENTS_DATE',
  SORT_HISTORICAL_SHARED_WITH_USER = 'SORT_HISTORICAL_SHARED_WITH_USER',
  SORT_HISTORICAL_ASSESSMENTS_START_DATE = 'SORT_HISTORICAL_ASSESSMENTS_START_DATE',
  SORT_HISTORICAL_ASSESSMENTS_END_DATE = 'SORT_HISTORICAL_ASSESSMENTS_END_DATE',
  SORT_REGISTRIES_ID = 'SORT_REGISTRIES_ID',
  SORT_REGISTRIES_NAME = 'SORT_REGISTRIES_NAME',
  SORT_REGISTRIES_ROLE = 'SORT_REGISTRIES_ROLE',
  SORT_REGISTRIES_REGISTRATION_DATE = 'SORT_REGISTRIES_REGISTRATION_DATE',
  SORT_REGISTRIES_BIRTH_DATE = 'SORT_REGISTRIES_BIRTH_DATE',
  SORT_REGISTRIES_DELETED = 'SORT_REGISTRIES_DELETED',
}
