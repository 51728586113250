import { Injectable } from '@angular/core';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { DialogsService } from '@services';

@Injectable({
  providedIn: 'root',
})
export class ResponsiveService {
  isInitialBreakpoint = true;

  constructor(private breakpointObserver: BreakpointObserver, private dialogsService: DialogsService) {}

  init() {
    this.breakpointObserver
      .observe([Breakpoints.Medium, Breakpoints.Large, Breakpoints.XLarge])
      .subscribe(() => {
        if (!this.isInitialBreakpoint) {
          this.dialogsService.dialog.closeAll();
        }
        this.isInitialBreakpoint = false;
      });
  }
}
