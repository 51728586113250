import { Component, Injector, OnInit } from '@angular/core';

import { StoreService } from '@services';
import { CommonLayout } from '@common/core/components/layout/common-layout.component';
import { eStoreActions } from '@enums';
import { IBitfStoreEvent } from '@interfaces';
import { Store } from '@models';

@Component({
  selector: 'app-desktop-layout',
  templateUrl: './desktop-layout.component.html',
  styleUrls: ['./desktop-layout.component.scss'],
})
export class DesktopLayoutComponent extends CommonLayout implements OnInit {
  constructor(public injector: Injector, public storeService: StoreService) {
    super(injector);
  }

  ngOnInit() {
    super.ngOnInit();
  }

  updateLayout() {
    // const data = this.route.snapshot.firstChild.data;
  }
}
