import { Component, Input, SimpleChanges, OnChanges } from '@angular/core';
import { Assessment } from '@web/core/models';
import { TranslateService } from '@ngx-translate/core';
import { IQualitativeSkillsChart } from '@web/interfaces';

@Component({
  selector: 'mra-qualitative-skills',
  templateUrl: './qualitative-skills.component.html',
  styleUrls: ['./qualitative-skills.component.scss'],
})
export class QualitativeSkillsComponent implements OnChanges {
  @Input() assessment: Assessment;

  Array = Array;
  qualitativeSkills: IQualitativeSkillsChart[];
  dataSourceSkills = [];
  displayedColumnsSkills: string[] = ['id', 'name', 'description', 'note', 'weight', 'score'];
  dataSourceOverall = [];
  displayedColumnsOverall: string[] = ['name', 'overallDescription'];

  constructor(private translateService: TranslateService) {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes.assessment.currentValue) {
      if (changes.assessment.currentValue.qualitativeSkills) {
        this.updateTable(changes.assessment.currentValue.qualitativeSkills);
        this.qualitativeSkills =
          changes.assessment.currentValue.qualitativeSkills?.length > 0
            ? [
                {
                  label: this.translateService.instant('COMMON.LABEL.SKILL.ACTUAL_LEVEL'),
                  qualitativeSkills: changes.assessment.currentValue.qualitativeSkills,
                },
              ]
            : [];
      }
    }
  }

  updateTable(qualitativeSkills) {
    this.dataSourceSkills = [];
    for (const skill of qualitativeSkills) {
      this.dataSourceSkills.push({
        id: skill.id,
        position: skill.question.position,
        name: skill.question.title,
        description: skill.question.description,
        weight: new Array(skill.weight),
        score: new Array(skill.score),
        notes: skill.notes,
      });
    }
    this.dataSourceOverall = [];
    this.dataSourceOverall.push({
      name: this.translateService.instant('COMMON.LABEL.SKILL.OVERALL_NOTE'),
      description: this.assessment.notes,
    });
  }
}
