import { EBitfTouchEventType } from '../../enums';

export class BitfTouchEvent {
  id: string;
  milliseconds: number;
  triggers: string[];
  eventType: EBitfTouchEventType;
  vibration: number[];
  sound: string;
  isNotified: boolean;
  handler?: () => void;

  constructor(event: Partial<BitfTouchEvent>) {
    Object.assign(this, event);
  }
}
