import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Assessment, QualitativeSkill } from '@web/core/models';

@Component({
  selector: 'mra-questionary-card',
  templateUrl: './questionary-card.component.html',
  styleUrls: ['./questionary-card.component.scss'],
})
export class QuestionaryCardComponent implements OnInit {
  @Input() qualitativeSkill: QualitativeSkill;
  @Input() skillsCount: number;
  @Input() readOnly = false;
  @Output() back = new EventEmitter<any>();
  @Output() next = new EventEmitter<any>();

  constructor() {}

  ngOnInit() {}

  onRate(value: number) {
    this.qualitativeSkill.score = value;
  }

  onBack() {
    this.back.emit();
  }

  onNext() {
    this.next.emit();
  }
}
