import { Injectable } from '@angular/core';
import { LocationStrategy, PathLocationStrategy } from '@angular/common';
import { Subject, Observable, merge, interval } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

import { environment } from '@env/environment';
import { IBitfLoggerParams } from '@interfaces';
import { StoreService } from '@services';

type EnhancedMessage = IBitfLoggerParams & {
  time: string;
  environment: string;
  appName: string;
  route: string;
  fullUrl: string;
  userId: string;
};

@Injectable({
  providedIn: 'root',
})
export class BitfLoggerService {
  private static readonly MAX_DELAY_MS = 5000;

  logEvents$: Observable<any> = new Subject();
  flushLogEvents$ = new Subject();

  constructor(private locationStrategy: LocationStrategy, private storeService: StoreService) {
    if (environment.loggerConfig.sendLogs) {
      this.logEvents$ = merge(interval(10000), this.flushLogEvents$).pipe(debounceTime(2000));
    }
  }

  log(params: IBitfLoggerParams) {
    if (window['ClientJS']) {
      this.addToLogMessagesPipe(this.createLogObj(params));
    } else {
      this.loadClientJs().then(
        () => {
          this.addToLogMessagesPipe(this.createLogObj(params));
        },
        () => {
          this.addToLogMessagesPipe(this.createLogObj(params));
        }
      );
    }
  }

  private createLogObj(params: IBitfLoggerParams): EnhancedMessage {
    const route =
      this.locationStrategy instanceof PathLocationStrategy
        ? this.locationStrategy.path()
        : window.location.href;
    const fullUrl = window.location.href;

    let userId;
    if (this.storeService.store.user && this.storeService.store.user.id) {
      userId = this.storeService.store.user.id.toString();
    }
    const logObj: EnhancedMessage = {
      ...params,
      appName: environment.appName,
      environment: environment.name,
      route,
      fullUrl,
      time: new Date().toJSON(),
      userId,
    };
    if ((window as any).ClientJS) {
      const clientJs = new window['ClientJS']();
      const browserData = clientJs.getBrowserData();
      Object.assign(logObj, {
        browser: browserData.browser,
        os: browserData.os,
        device: clientJs.getDeviceType(),
        timeZone: clientJs.getTimeZone(),
      });
    }
    return logObj;
  }

  private addToLogMessagesPipe(message: EnhancedMessage) {
    const keyName = `${environment.appName}-logs`;
    const logs = localStorage.getItem(keyName);
    let logMessagesArray = [];
    if (logs) {
      logMessagesArray = JSON.parse(logs);
    }

    const isDuplicate = logMessagesArray.some(logMsg => this.messageEquals(message, logMsg));
    if (!isDuplicate) {
      logMessagesArray.unshift(message);
    }
    // Trim the array to avoid overflow
    if (logMessagesArray.length > environment.loggerConfig.maxLogRentention) {
      logMessagesArray.length = environment.loggerConfig.maxLogRentention;
    }
    localStorage.setItem(keyName, JSON.stringify(logMessagesArray));

    if (environment.loggerConfig.sendLogs) {
      this.flushLogEvents$.next();
    }

    if (!environment.production && message.level === 'ERROR') {
      console.error(message);
    }
  }

  private loadClientJs() {
    return new Promise((success, error) => {
      const script = document.createElement('script');
      script.type = 'text/javascript';
      script.src = 'https://cdnjs.cloudflare.com/ajax/libs/ClientJS/0.1.11/client.min.js';
      script.onload = success;
      script.onerror = error;
      document.body.appendChild(script);
    });
  }

  private messageEquals(msgA: EnhancedMessage, msgB: EnhancedMessage) {
    const elapsed = Math.abs(new Date(msgA.time).getTime() - new Date(msgB.time).getTime());
    if (elapsed <= BitfLoggerService.MAX_DELAY_MS) {
      const copyA = Object.assign({}, msgA);
      delete copyA.time;
      const copyB = Object.assign({}, msgB);
      delete copyB.time;

      return JSON.stringify(copyA) === JSON.stringify(copyB);
    }

    return false;
  }
}
