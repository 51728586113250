<mat-form-field class="{{matFormFieldClass}}"
  [appearance]="appearance">
  <mat-label>{{formItem.label}}</mat-label>
  <mat-select [disabled]="formItem?.isDisabled || !formItem.isEditable"
    [compareWith]="compareWith"
    [multiple]="multiple"
    [placeholder]="formItem.label || label"
    [formControl]="formControl"
    (selectionChange)="onSelectionChange($event)"
    #matSelectComponent>

    <mat-option *ngIf="selectUnselectAllFields"
      disabled="disabled"
      class="form-option-select-unselect-all">
      <button mat-button
        class="mat-primary fill text-sm"
        (click)="selectAll()">
        {{ 'BITF.LABEL.SELECT_ALL' | translate }}
      </button>
      <button mat-button
        class="mat-accent fill text-sm"
        (click)="deselectAll()">
        {{ 'BITF.LABEL.UNSELECT_ALL' | translate }}
      </button>
    </mat-option>

    <!-- Grouped -->
    <ng-container *ngIf="formItem.isGrouped">
      <mat-optgroup *ngFor="let group of formItem.options"
        [label]="group.label">
        <mat-option *ngFor="let option of group.options"
          [value]="useObject ? option : option?.id"
          [matTooltip]="option.label.length > 20 ? option.label : ''">
          <div class="d-flex align-items-center">
            <span>{{ option?.label }}</span>
            <span class="icon ml-auto mr-0 c-warm"
              *ngIf="option.metaData?.isDeleted"></span>
          </div>
        </mat-option>
      </mat-optgroup>
    </ng-container>

    <!-- Default -->
    <ng-container *ngIf="!formItem.isGrouped">
      <mat-option *ngFor="let option of formItem.options"
        [value]="useObject ? option : option?.id"
        [matTooltip]="option.label.length > 20 ? option.label : ''">
        <div class="d-flex align-items-center">
          <span>{{ option?.label }}</span>
          <span class="icon ml-auto mr-0 c-warm"
            *ngIf="option.metaData?.isDeleted"></span>
        </div>
      </mat-option>
    </ng-container>

  </mat-select>
</mat-form-field>