import { Component } from '@angular/core';
import { BitfPaginatorComponent } from '../bitf-paginator.component';
import { PageEvent } from '@angular/material/paginator';

@Component({
  selector: 'bitf-mat-paginator',
  templateUrl: './bitf-mat-paginator.component.html',
  styleUrls: ['./bitf-mat-paginator.component.scss'],
})
export class BitfMatPaginatorComponent extends BitfPaginatorComponent {
  onMatPagechange(event: PageEvent) {
    this.pagination.page = event.pageIndex + this.pageIndexOffset;
    this.pagination.size = event.pageSize;
    this.onPageChange(this.pagination);
  }
}
