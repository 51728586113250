import { FormGroup, Validators, FormArray, AbstractControl } from '@angular/forms';
import { Subject, Observable } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import createNumberMask from 'text-mask-addons/dist/createNumberMask';
import { BitfFormItemConfig } from '@bitf/core/models';
import { EventEmitter } from '@angular/core';
import { EBitfFormControlValidatorsKeys } from '../enums';

export const numberMask = (config: any = {}) => {
  const defaultConfig = {
    prefix: '',
    suffix: '',
    includeThousandsSeparator: true,
    thousandsSeparatorSymbol: ',',
    allowDecimal: false,
    decimalSymbol: '.',
    decimalLimit: 0,
    integerLimit: 12,
    requireDecimal: false,
    allowNegative: false,
    allowLeadingZeroes: false,
  };
  return createNumberMask(Object.assign(defaultConfig, config));
};

export const bitfSetFormItemsConfigForFiltering = (bitfFormItemConfig: BitfFormItemConfig) => {
  Object.values(bitfFormItemConfig).forEach(formItemConfig => {
    // isEditable: backward compatibility
    formItemConfig.isEditable = true;
    formItemConfig.isDisabled = false;
    formItemConfig.isRequired = false;
  });
};

export const bitfSetFormControlsRequired = (
  form: FormGroup,
  requiredFormControls: { [k: string]: { isRequired?: boolean } }
) => {
  Object.keys(requiredFormControls)
    .filter(key => requiredFormControls[key] && requiredFormControls[key].isRequired)
    .forEach(formControlName => bitfSetFormControlRequired(form, formControlName, true));
};

export const bitfSetFormControlRequired = (form: FormGroup, formControlName: string, isRequired = false) => {
  const formControl = form.get(formControlName);
  if (!formControl) {
    return;
  }
  if (isRequired) {
    formControl.setValidators([Validators.required]);
  } else {
    // FIXME: this must remove the required not remove all validators
    formControl.setValidators(null);
  }
  formControl.updateValueAndValidity();
};

export function bitfAddAsteriskToRequiredFormItemConfig(formItemsConfig: BitfFormItemConfig) {
  Object.keys(formItemsConfig).forEach(key => {
    if (
      formItemsConfig[key] &&
      (formItemsConfig[key].isRequired ||
        (formItemsConfig[key].validators &&
          !!formItemsConfig[key].validators.find(
            validator => validator.key === EBitfFormControlValidatorsKeys.required
          )))
    ) {
      formItemsConfig[key].label += ' *';
    }
  });
}

export const bitfMarkAllFormControlsAsTouched = (form: FormGroup) => {
  Object.keys(form.controls).forEach(controlName => {
    form.get(controlName).markAsTouched();
  });
};

export class BitfCaretPosition {
  private _input;
  private inputCursorPosition;
  private inputValueLength;

  get input() {
    return this._input;
  }

  set input(input) {
    this._input = input;
  }

  constructor() {}

  store() {
    if (!this.input) {
      return;
    }
    this.inputCursorPosition = this.getCaretPosition();
    this.inputValueLength = String(this.input.value).length;
  }

  restore() {
    if (!this.inputCursorPosition || !this.input) {
      return;
    }
    const lengthChange = String(this.input.value).length - this.inputValueLength;
    this.inputCursorPosition.end += lengthChange;
    this.inputCursorPosition.start += lengthChange;
    setTimeout(() => {
      this.setCaretPosition();
    });
  }

  private getCaretPosition() {
    // IE < 9 Support
    if (document['selection']) {
      this.input.focus();
      const range = document['selection'].createRange();
      const rangelen = range.text.length;
      range.moveStart('character', -this.input.value.length);
      const start = range.text.length - rangelen;
      return { start, end: start + rangelen };
    }
    // IE >=9 and other browsers
    if (this.input.selectionStart || this.input.selectionStart === '0') {
      return { start: this.input.selectionStart, end: this.input.selectionEnd };
    } else {
      return { start: 0, end: 0 };
    }
  }

  private setCaretPosition() {
    const { start, end } = this.inputCursorPosition;
    // IE >= 9 and other browsers
    if (this.input.setSelectionRange) {
      this.input.focus();
      this.input.setSelectionRange(start, end);
      return;
    }
    if (this.input.createTextRange) {
      const range = this.input.createTextRange();
      range.collapse(true);
      range.moveEnd('character', end);
      range.moveStart('character', start);
      range.select();
    }
  }
}
export const bitfMarkAllFormControlsAsUntouched = (form: FormGroup) => {
  Object.keys(form.controls).forEach(controlName => {
    form.get(controlName).markAsUntouched();
  });
};

export function bitfMarkFormGroupTouched(formGroup: FormGroup) {
  (Object as any).values(formGroup.controls).forEach(control => {
    control.markAsTouched();
    control.markAsDirty();

    // NOTE: https://github.com/angular/angular/blob/master/packages/forms/src/model.ts
    (control.statusChanges as EventEmitter<any>).emit(control.status);

    if (control.controls) {
      bitfMarkFormGroupTouched(control);
    }
  });
}

export function bitfMarkFormGroupUntouched(formGroup: FormGroup) {
  (Object as any).values(formGroup.controls).forEach(control => {
    control.markAsUntouched();
    control.markAsPristine();

    // NOTE: https://github.com/angular/angular/blob/master/packages/forms/src/model.ts
    (control.statusChanges as EventEmitter<any>).emit(control.status);

    if (control.controls) {
      bitfMarkFormGroupUntouched(control);
    }
  });
}

export function bitfMarkFormGroupSetDisabled(formGroup: FormGroup, disabled = true) {
  (Object as any).values(formGroup.controls).forEach(control => {
    control.disabled = disabled;

    if (control.controls) {
      control.controls.forEach(c => bitfMarkFormGroupSetDisabled(c, disabled));
    }
  });
}

interface IBitfMultipleCheckboxSelection {
  someSelected: boolean;
  allSelected: boolean;
  numberOfSelectedItems: number;
  numberOfUnselectedItems: number;
  numberOfItems: number;
}

export function bitfSelectAllCheckbox(
  formArray: FormArray,
  selectAll: AbstractControl,
  destroy$: Observable<any>
) {
  const subject = new Subject<IBitfMultipleCheckboxSelection>();
  function update() {
    let someSelected = false;
    let allSelected = formArray.controls.length > 0;
    let numberOfSelectedItems = 0;
    let numberOfUnselectedItems = 0;

    formArray.controls.forEach(control => {
      const currentSelected: boolean = control.value;
      someSelected = someSelected || currentSelected;
      allSelected = allSelected && currentSelected;
      currentSelected ? numberOfSelectedItems++ : numberOfUnselectedItems++;
    });

    selectAll.setValue(allSelected, { emitEvent: false });
    subject.next({
      someSelected,
      allSelected,
      numberOfSelectedItems,
      numberOfUnselectedItems,
      numberOfItems: formArray.controls.length,
    });
  }

  selectAll.valueChanges.pipe(takeUntil(destroy$)).subscribe((value: boolean) => {
    formArray.controls.forEach(control => control.setValue(value));
  });
  formArray.valueChanges.pipe(takeUntil(destroy$)).subscribe(() => update());
  update();

  return subject;
}

export function bitfFormArrayRemoveAllItems(formArray: FormArray) {
  while (formArray.length !== 0) {
    formArray.removeAt(0);
  }
}
