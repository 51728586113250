<div class="sidenav-menu-content h-100 p-3">
    <mat-action-list>
        <div mat-list-item
            *ngFor="let item of menu"
            class="pb-3">
            <button mat-flat-button
                [color]="btn.isActive ? 'accent' : null"
                class="w-100 text-left"
                [routerLink]="item.routerLink"
                routerLinkActive
                #btn="routerLinkActive">
                <mat-icon>{{item.icon}}</mat-icon> <span class="pl-3">{{item.label | translate}}</span>
            </button>
        </div>
    </mat-action-list>
</div>
<h3 class="sidenav-menu-content fixed-bottom p-3 m-0">
    <b>{{storeService.store.user?.fullName}} ({{storeService.store.user?.profile}})</b>
</h3>