<div class="d-flex w-100 h-100">
    <mat-card class="w-100 p-2 d-flex flex-column justify-content-between">
        <h2 class="text-center w-100">{{'COMMON.LABEL.AVERAGE' | translate}} {{title}}</h2>
        <div>
            <div class="position-relative w-100">
                <chart *ngIf="chartdata"
                    class="w-100"
                    [type]="chartdata.type"
                    [data]="chartdata.data"
                    [options]="chartdata.options"></chart>

                <div class="gauge-chart-value">
                    <span>{{value | number:'1.1-1'}}</span>
                </div>
            </div>
            <div class="w-100 d-flex justify-content-center"
                *ngIf="percentage">
                <div class="w-75 text-center">
                    {{percentage}} {{'COMMON.LABEL.COMPARED_WITH_AVG_PHARMACIES' | translate}}
                </div>
            </div>
            <div class="w-100 d-flex justify-content-center"
                *ngIf="previousYearPercentage != null">
                <div class="w-75 text-center">
                    <strong>{{previousYearPercentage}}</strong>
                    {{'COMMON.LABEL.COMPARED_WITH_PREVIOUS_YEAR' | translate}}
                </div>
            </div>
        </div>

        <ng-container *ngIf="average !== undefined">
            <div class="row mt-3 px-3 px-md-0 px-lg-3 align-items-center">
                <div class="col-5">
                    <span class="square-color"
                        [ngStyle]="{'background-color': CONSTANTS.COLOR_GREEN}"></span>
                </div>
                <div class="col-14">
                    <span class="mat-caption">{{'COMMON.LABEL.YOUR_PHARMACY' | translate}}</span>
                </div>
                <div class="col-5">
                    <h2 class="p-0 m-0 font-weight-bold text-center">{{value | number:'1.1-1'}}</h2>
                </div>
            </div>

            <div class="row mt-3 px-3 px-md-0 px-lg-3 align-items-center"
                *ngIf="average > 0">
                <div class="col-5">
                    <span class="square-color"
                        [ngStyle]="{'background-color': CONSTANTS.COLOR_ORANGE}"></span>
                </div>
                <div class="col-14">
                    <span class="mat-caption">{{'COMMON.LABEL.AVERAGE_PHARMACIES' | translate}}</span>
                </div>
                <div class="col-5">
                    <h2 class="p-0 m-0 font-weight-bold text-center">{{average | number:'1.1-1'}}</h2>
                </div>
            </div>
        </ng-container>
    </mat-card>
</div>