import { BitfODataPathBuilder } from '@bitf/core/parsers/odata-parser/bitf-odata-path.builder';
import { bitfGetProp } from '@common/libs/bitforce/utils/bitf-objects.utils';
import { IBitfApiRequest } from '@web/interfaces';

export class AppPathBuilder extends BitfODataPathBuilder {
  build(requestParams: IBitfApiRequest = {}): string {
    const id = bitfGetProp(requestParams, 'body', 'id');
    // ID is not supported in some posts
    if (id) {
      delete requestParams.body.id;
    }
    const response = super.build(requestParams);
    if (id) {
      requestParams.body.id = id;
    }
    return response;
  }
}
