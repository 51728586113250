import { IBitfApiCallState } from '@interfaces';
import { BitfApiCallStateMapper } from '@bitf/core/api-call-state/bitf-api-call-state.mapper';

export class DefaultApiCallStateMapper extends BitfApiCallStateMapper {
  buildApiRequest(apiCallState: IBitfApiCallState): void {
    apiCallState.apiRequest.filter = this.computedApiRequestParts.filter
      .filter(filterString => filterString)
      .join(' and ');

    apiCallState.apiRequest.sorting = this.computedApiRequestParts.sorting;
  }
}
