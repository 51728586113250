import { SuperModel } from './super-model.model';

export class QuestionCategory extends SuperModel {
  name: string;

  constructor(data: Partial<QuestionCategory>) {
    super(data);
  }

  get serialised() {
    return {};
  }
}
