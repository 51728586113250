import { BitfApiRequestPart } from '@bitf/core/api-call-state/bitf-api-request-part';
import { bitfGetProp } from '@bitf/utils/bitf-objects.utils';
import { ONE_DAY_MS } from '@common/libs/bitforce/utils/bitf-dates.utils';
import { EBitfApiRequestPartsNames, EApiRequestPartKeys } from '@enums';
import { IBitfPartData } from '@interfaces';

export class BitfODataFormItemConfigApiRequestPart extends BitfApiRequestPart {
  constructor({
    key,
    partName,
    defaultPartData,
  }: {
    key?: EApiRequestPartKeys;
    partName?: EBitfApiRequestPartsNames;
    defaultPartData?: IBitfPartData;
  } = {}) {
    if (!key) {
      key = EApiRequestPartKeys.FILTERS;
    }
    if (!partName) {
      partName = EBitfApiRequestPartsNames.FILTER;
    }
    super(key, partName, defaultPartData);
  }

  build(): void {
    let part = '';
    Object.keys(this.formValue).forEach(formItem => {
      const filterExpression = bitfGetProp(
        this.data,
        'formItemsConfig',
        formItem,
        'metadata',
        'filter',
        'expression'
      );
      const filter = this.formValue[formItem]
        ? this.buildFilter(this.formValue[formItem], filterExpression, formItem)
        : '';
      if (filter) {
        const filterConjunctionOperator = bitfGetProp(
          this.data,
          'formItemsConfig',
          formItem,
          'metadata',
          'filter',
          'conjunction'
        );
        if (part === '') {
          part = filter;
        } else {
          part += ` ${filterConjunctionOperator} ${filter}`;
        }
      }
    });
    this.part = part;
  }

  buildFilter(formItemValue: any, searchExpression: string, formItem: string) {
    if (!searchExpression) {
      return '';
    }
    switch (true) {
      case formItemValue instanceof Date:
        formItemValue = formItemValue.toISOString();
        break;
      case !isNaN(formItemValue):
        formItemValue = +formItemValue;
        break;
    }
    return `${searchExpression.replace(new RegExp('{{keyword}}', 'g'), formItemValue)}`;
  }
}
