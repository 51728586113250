import { SuperModel } from './super-model.model';

export class PharmacyStatistics extends SuperModel {
  pharmacyId: number;
  subRoleId: number;
  surveyDate: Date;
  surveyId: number;
  weightedAverageByPharmacy: number;
  weightedAverageBySubrole: number;
  weightedAverageByOtherPharmacies: number;

  constructor(data: Partial<PharmacyStatistics>) {
    super(data);
  }

  get serialised() {
    return {};
    // return {
    //   id: this.id,
    //   pharmacyId: this.pharmacyId,
    //   subRoleId: this.subRoleId,
    //   surveyDate: this.surveyDate,
    //   surveyId: this.surveyId,
    //   weightedAverageByPharmacy: this.weightedAverageByPharmacy,
    //   weightedAverageBySubrole: this.weightedAverageBySubrole,
    //   weightedAverageByOtherPharmacies: this.weightedAverageByOtherPharmacies,
    // };
  }
}

export interface IAssessmentsData {
  subRoles: {
    subRoleId: number;
    count: number;
    average: number;
  }[];
  monthly: number[];
}
