import { Component, OnInit, Input } from '@angular/core';

import { IChart } from '@interfaces';
import { CONSTANTS } from '@web/constants';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'mra-gauge-chart',
  templateUrl: './gauge-chart.component.html',
  styleUrls: ['./gauge-chart.component.scss'],
})
export class GaugeComponent implements OnInit {
  @Input() title: string | number;
  @Input() value: number;
  @Input() average: number;
  @Input() previousValue?: number;
  percentage: string;
  previousYearPercentage: string;

  CONSTANTS = CONSTANTS;
  chartdata: IChart;

  constructor(private translateService: TranslateService) {}

  ngOnInit(): void {
    const maxValue = Math.abs(CONSTANTS.MAX_CHART_VALUE - this.value);
    const dataValues = [this.value.toFixed(1), maxValue];
    this.chartdata = {
      type: 'doughnut',
      data: {
        labels: [],
        datasets: [
          {
            label:
              this.average !== undefined
                ? this.translateService.instant('COMMON.LABEL.YOUR_PHARMACY')
                : this.translateService.instant('COMMON.LABEL.ALL_PHARMACY_AVERAGE'),
            backgroundColor: [CONSTANTS.COLOR_GREEN],
            data: dataValues,
          },
        ],
      },
      options: {
        legend: {
          display: false,
        },
        cutoutPercentage: 70,
        circumference: Math.PI,
        rotation: -1 * Math.PI,
        suggestedMax: 100,
        tooltips: {
          filter: (tooltipItem, data) => {
            return tooltipItem.index < dataValues.length - 1;
          },
          callbacks: {
            label: (tooltipItem, data) => {
              let label = data.datasets[tooltipItem.datasetIndex].label || '';

              if (label) {
                label += ': ';
              }
              label += Math.round(data.datasets[tooltipItem.datasetIndex].data[0] * 10) / 10;
              return label;
            },
          },
        },
      },
    };

    if (this.average > 0) {
      const percentageValue = Math.round(((this.value - this.average) / this.average) * 100);
      this.percentage = Number.isFinite(percentageValue)
        ? percentageValue > 0
          ? `+${percentageValue}%`
          : `${percentageValue}%`
        : null;
      const averageMaxValue = Math.abs(CONSTANTS.MAX_CHART_VALUE - this.average);
      const averageDataValues = [this.average, averageMaxValue];
      this.chartdata.data.datasets.push({
        label: this.translateService.instant('COMMON.LABEL.AVERAGE_PHARMACIES'),
        backgroundColor: [CONSTANTS.COLOR_ORANGE],
        data: averageDataValues,
      });
    }

    if (this.previousValue > 0 && this.value != null) {
      const percentageValue = Math.round(((this.value - this.previousValue) / this.previousValue) * 100);
      this.previousYearPercentage = Number.isFinite(percentageValue)
        ? percentageValue > 0
          ? `+${percentageValue}%`
          : `${percentageValue}%`
        : null;
    }
  }
}
