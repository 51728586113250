export function bitfCapitalize(text) {
  return text[0].toUpperCase() + text.slice(1);
}

export function bitfUncapitalize(text) {
  return text[0].toLowerCase() + text.slice(1);
}

export function bitfCamelCaseToSnakeCase(text) {
  return text
    .replace(/(?:^|\.?)([A-Z])/g, (x, y) => {
      return '_' + y.toLowerCase();
    })
    .replace(/^_/, '');
}

export function bitfSnakeCaseToCamelCase(text) {
  return text.replace(/([-_][a-z])/gi, $1 => {
    return $1
      .toUpperCase()
      .replace('-', '')
      .replace('_', '');
  });
}
