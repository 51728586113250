import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { QuantitativeSkill } from '@web/core/models';

@Component({
  selector: 'mra-quantitative-skills',
  templateUrl: './quantitative-skills.component.html',
  styleUrls: ['./quantitative-skills.component.scss'],
})
export class QuantitativeSkillsComponent implements OnInit, OnChanges {
  @Input() quantitativeSkills: QuantitativeSkill[];
  @Input() onlyTable = false;
  dataSource: Skill[] = [];

  constructor() {}

  ngOnInit() {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes.quantitativeSkills.currentValue) {
      this.updateTable(changes.quantitativeSkills.currentValue);
    }
  }

  updateTable(quantitativeSkills: QuantitativeSkill[]) {
    this.dataSource = [];
    for (const skill of quantitativeSkills) {
      const index = this.dataSource.findIndex(d => d.areaId === skill.areaId);
      if (index === -1) {
        this.dataSource.push({
          areaId: skill.areaId,
          areaText: skill.areaText,
          questions: [skill],
        });
      } else {
        this.dataSource[index].questions.push(skill);
      }
    }
  }
}

interface Skill {
  areaId: number;
  areaText: string;
  questions: QuantitativeSkill[];
}
