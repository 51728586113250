import { Component, OnInit, Input } from '@angular/core';
import { MatDrawer } from '@angular/material/sidenav';

@Component({
  selector: 'mra-main-header',
  templateUrl: './main-header.component.html',
  styleUrls: ['./main-header.component.scss'],
})
export class MainHeaderComponent implements OnInit {
  @Input() drawer: MatDrawer;

  constructor() {}

  ngOnInit(): void {}

  onToggleSidenav() {
    this.drawer.toggle();
  }
}
