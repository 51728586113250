import { Injectable, Injector } from '@angular/core';
import { BitfQueryStringTokenAuthService } from '@bitf/services/auth/query-string-token/bitf-query-string-token-auth.service';
import { BitfJwtAuthService } from '@bitf/services/auth/jwt/bitf-jwt-auth.service';
import { IBitfJwtTokenMetadata, IBitfQueryStringLogin } from '@web/interfaces';

@Injectable({
  providedIn: 'root',
})
export class AuthService extends BitfQueryStringTokenAuthService<IBitfJwtTokenMetadata> {
  tokenAlertTresholdMinutes = 5;
  constructor(protected injector: Injector) {
    super(injector);
  }

  decodeToken(loginResponse: IBitfQueryStringLogin): IBitfJwtTokenMetadata {
    return BitfJwtAuthService.decodeToken(loginResponse);
  }
}
