import { SuperModel } from './super-model.model';

export class QuantitativeSkill extends SuperModel {
  absoluteIndex: number;
  apotecaScore: number;
  areaId: number;
  areaText: string;
  assessmentId: number;
  averageScore: number;
  code: string;
  customerCode: string;
  externalAreaId: number;
  externalKPIId: number;
  kpiId: number;
  kpiText: string;
  nationCode: string;
  operatorCode: string;
  pharmacyId: number;
  userId: number;
  weight: number;

  constructor(data: Partial<QuantitativeSkill>) {
    super(data);
  }

  get serialised() {
    return {
      id: this.id,
      absoluteIndex: this.absoluteIndex,
      apotecaScore: this.apotecaScore,
      areaId: this.areaId,
      areaText: this.areaText,
      assessmentId: this.assessmentId,
      averageScore: this.averageScore,
      code: this.code,
      customerCode: this.customerCode,
      externalAreaId: this.externalAreaId,
      externalKPIId: this.externalKPIId,
      kpiId: this.kpiId,
      kpiText: this.kpiText,
      nationCode: this.nationCode,
      operatorCode: this.operatorCode,
      pharmacyId: this.pharmacyId,
      userId: this.userId,
      weight: this.weight,
    };
  }
}
