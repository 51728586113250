import * as Models from '@models';
import { BitfFormItemConfig } from '@common/libs/bitforce/core/models';

export const modelsMap = new Map<string, any>([
  ['form-items-config', BitfFormItemConfig],
  ['users', Models.User],
  ['pharmacies', Models.Pharmacy],
  ['pharmacy-statistics', Models.PharmacyStatistics],
  ['assessments', Models.Assessment],
  ['surveys', Models.Survey],
  ['questions', Models.Question],
]);
