import { Injectable, Injector } from '@angular/core';
import { Subject, Observable } from 'rxjs';

import { EBitfApiCallStateActions } from '@enums';
import { IBitfApiCallState, IBitfStoreEvent } from '@interfaces';
import { apiCallStateConfig } from '@api-call-state';
import { BitfApiRequestPart } from '@bitf/core/api-call-state/bitf-api-request-part';
import { filter, map } from 'rxjs/operators';
import { StoreService } from '@services';
import { Store } from '@models';
import { IBitfStoreParts } from '../../api-call-state/bitf-api-call-state.interfaces';

@Injectable({
  providedIn: 'root',
})
export class BitfApiCallStateService {
  apiCallStates$ = new Subject<IBitfApiCallState>();
  private apiCallStates = new Map<string, IBitfApiCallState>();
  private storeService: StoreService;

  constructor(injector: Injector) {
    this.storeService = injector.get(StoreService);
  }

  init() {
    if (!apiCallStateConfig) {
      return;
    }
    apiCallStateConfig.forEach((apiCallState: IBitfApiCallState) => {
      this.initRequestParts(apiCallState);
      if (apiCallState.apiCallStateMapper) {
        apiCallState.apiCallStateMapper.mapApiCallState(apiCallState);
      }
      this.apiCallStates.set(apiCallState.apiCallStateName, apiCallState);
    });
  }

  public setStore(fn: (store: IBitfStoreParts) => void, apiCallStateName: string) {
    this.storeService.setStore((store: Store) => {
      fn(store.apiCallState[apiCallStateName]);
      this.updateApiCallState(apiCallStateName);
    }, this.getStoreActionFor(apiCallStateName));
  }

  private getStoreActionFor(apiCallStateName: string) {
    return `${EBitfApiCallStateActions.UPDATE_API_CALL_STATE}.${apiCallStateName}`;
  }

  private initRequestParts(apiCallState: IBitfApiCallState) {
    const apiCallStateName = apiCallState.apiCallStateName;
    (apiCallState.requestParts || []).forEach((requestPart: BitfApiRequestPart) => {
      const storeApiCallState = this.storeService.store.apiCallState;
      storeApiCallState[apiCallStateName] = storeApiCallState[apiCallStateName] || {};
      requestPart.init(storeApiCallState[apiCallStateName]);
    });
  }

  private updateApiCallState(apiCallStateName: string) {
    const apiCallState = this.getApiCallState(apiCallStateName);
    if (apiCallState.apiCallStateMapper) {
      apiCallState.apiCallStateMapper.mapApiCallState(apiCallState);
    }
    // TODO: can be removed when deprecated sections will be remvoed
    this.apiCallStates$.next(apiCallState);
  }

  /**
   * @deprecated Use apiPart.setStore(...) instead
   */
  dispatchApiCallState(action: string, apiCallStateName: string) {
    let apiCallState: IBitfApiCallState;
    switch (action) {
      case EBitfApiCallStateActions.UPDATE:
        this.setStore(() => {}, apiCallStateName);
        break;

      case EBitfApiCallStateActions.RESET_ALL_FILTERS:
        apiCallState = this.apiCallStates.get(apiCallStateName);
        apiCallState.requestParts.forEach((requestPart: BitfApiRequestPart) => requestPart.reset());

        // tslint:disable-next-line: deprecation
        this.dispatchApiCallState(EBitfApiCallStateActions.UPDATE, apiCallStateName);
        break;
    }
  }

  getApiCallState(apiCallStateName: string): IBitfApiCallState {
    let apiCallState: IBitfApiCallState = this.apiCallStates.get(apiCallStateName);
    if (!apiCallState) {
      apiCallState = this.getDefaultApiCallState(apiCallStateName);
      this.apiCallStates.set(apiCallStateName, apiCallState);
    }
    return apiCallState;
  }

  getApiRequest(apiCallStateName: string) {
    return this.getApiCallState(apiCallStateName).apiRequest || {};
  }

  getDefaultApiCallState(apiCallStateName: string) {
    const defaultApiCallState = apiCallStateConfig[apiCallStateName];
    if (defaultApiCallState) {
      return defaultApiCallState;
    }
    return { apiCallStateName } as IBitfApiCallState;
  }

  getRequestPart(apiCallStateName: string, apiRequestPartKey: string) {
    const aCS = this.getApiCallState(apiCallStateName);
    const apiRequestPart = aCS.requestParts.find(
      (requestPart: BitfApiRequestPart) => requestPart.key === apiRequestPartKey
    );
    if (!apiRequestPart) {
      throw new Error(
        // tslint:disable-next-line:max-line-length
        'You have to add correct object of class BitfApiRequestPart to DEFAULT_API_CALL_STATES -> request_parts'
      );
    }
    return apiRequestPart;
  }

  /**
   * @deprecated Use getStateStore$(...)
   */
  getApiCallStateOservable(apiCallStateName: string) {
    return this.apiCallStates$.pipe(
      filter((apiCallState: IBitfApiCallState) => apiCallState.apiCallStateName === apiCallStateName)
    );
  }

  public getStateStore$(apiCallStateName: string): Observable<IBitfStoreEvent<IBitfStoreParts>> {
    return this.storeService.selectStore(this.getStoreActionFor(apiCallStateName)).pipe(
      map(
        (event: IBitfStoreEvent<Store>): IBitfStoreEvent<IBitfStoreParts> => {
          return {
            action: event.action,
            store: event.store.apiCallState[apiCallStateName],
          };
        }
      )
    );
  }
}
