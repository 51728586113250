import {
  Directive,
  Input,
  OnInit,
  Renderer2,
  ViewContainerRef,
  TemplateRef,
  OnDestroy,
  EmbeddedViewRef,
} from '@angular/core';
import { filter, tap } from 'rxjs/operators';
import { Subscription } from 'rxjs';

import { UiRoleManagerService, StoreService } from '@services';
import { ERoleMode } from '@enums';

import { IBitfUiRoleManagerConfig } from './bitf-ui-role-manager.interface';

@Directive({
  selector: '[bitfUiRoleManager]',
})
export class BitfUiRoleManagerDirective implements OnInit, OnDestroy {
  // tslint:disable-next-line:no-input-rename
  @Input('bitfUiRoleManager')
  config: IBitfUiRoleManagerConfig = {};
  nativeElement: HTMLElement;

  subscription: Subscription = new Subscription();
  embededViewRef: EmbeddedViewRef<any>;

  constructor(
    private viewRef: ViewContainerRef,
    private templateRef: TemplateRef<any>,
    private renderer: Renderer2,
    private uiRoleManagerService: UiRoleManagerService,
    private storeService: StoreService
  ) {}

  ngOnInit(): void {
    this.config = this.config || {};
    if (!this.config || !this.config.action) {
      this.createComponent();
      return;
    }

    if (!this.config.mode) {
      this.config.mode = ERoleMode.HIDDEN;
    }
    this.mutateView();
    this.listenForStoreActions();
  }

  private mutateView() {
    const canI = this.uiRoleManagerService.canI(this.config.action, this.config.data);

    switch (this.config.mode) {
      case ERoleMode.HIDDEN:
        if (canI) {
          this.createEmbeddedView();
          this.setVisibility('visible');
        } else if (this.embededViewRef) {
          this.embededViewRef.destroy();
          this.embededViewRef = undefined;
        }
        break;
      case ERoleMode.INVISIBLE:
        this.createEmbeddedView();
        if (!this.isNativeElementReal()) {
          return;
        }
        setTimeout(() => {
          if (canI) {
            this.setVisibility('visible');
          } else if (this.embededViewRef) {
            this.setVisibility('hidden');
          }
        }, 0);
        break;
      case ERoleMode.DISABLED:
        this.createEmbeddedView();
        if (!this.isNativeElementReal()) {
          return;
        }
        setTimeout(() => {
          if (!canI) {
            this.renderer.setAttribute(this.nativeElement, 'disabled', 'true');
          } else {
            this.renderer.removeAttribute(this.nativeElement, 'disabled');
          }
          this.setVisibility('visible');
        }, 0);
        break;
      case ERoleMode.READ_ONLY:
        this.createEmbeddedView();
        if (!this.isNativeElementReal()) {
          return;
        }
        setTimeout(() => {
          if (!canI) {
            this.renderer.setAttribute(this.nativeElement, 'readonly', 'true');
          } else {
            this.renderer.removeAttribute(this.nativeElement, 'readonly');
          }
          this.setVisibility('visible');
        }, 0);
        break;
    }
  }

  private createEmbeddedView() {
    const viewContainerRef: any = this.createComponent();
    this.nativeElement = viewContainerRef.rootNodes[0];
    if (this.isNativeElementReal()) {
      this.renderer.addClass(this.nativeElement, 'uiRolesManager');
      this.setVisibility('hidden');
    }
  }

  private setVisibility(state: string) {
    if (this.isNativeElementReal()) {
      this.renderer.setStyle(this.nativeElement, 'visibility', state);
    }
  }

  private createComponent(): any {
    if (!this.embededViewRef) {
      this.embededViewRef = this.viewRef.createEmbeddedView(this.templateRef, {});
    }

    return this.embededViewRef;
  }

  private isNativeElementReal(): boolean {
    return this.nativeElement.innerHTML !== undefined;
  }

  private listenForStoreActions() {
    if (!this.config.storeActionsToListen || !this.config.storeActionsToListen.length) {
      return;
    }

    this.subscription.add(
      this.storeService.store$
        .pipe(
          filter(store => {
            return this.config.storeActionsToListen.includes(store.action);
          }),
          tap(() => this.mutateView())
        )
        .subscribe()
    );
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
