import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';

@Component({
  selector: 'mra-questionary-rating',
  templateUrl: './questionary-rating.component.html',
  styleUrls: ['./questionary-rating.component.scss'],
})
export class QuestionaryRatingComponent implements OnInit {
  @Input() score: number;
  @Input() readOnly = false;
  @Output() rate = new EventEmitter<number>();
  assessmentValues = Array.from(Array(7).keys(), (_, i) => i + 1);

  constructor() {}

  ngOnInit() {}

  onScoreClick(value) {
    if (!this.readOnly) {
      this.score = value;
      this.rate.emit(value);
    }
  }
}
