<mat-accordion>
    <mat-expansion-panel class="rounded-0"
        [expanded]="false">
        <mat-expansion-panel-header>
            <mat-panel-title class="my-3">
                <div class="d-flex align-items-center w-100">
                    <ng-container *ngIf="!isNew">
                        <mat-icon>drag_indicator</mat-icon>
                        <span class="mat-title ml-2 mb-0">{{question?.position + 1}}.
                            {{question?.title}}</span>
                    </ng-container>
                    <span class="mat-title ml-2 mb-0"
                        *ngIf="isNew">{{'SURVEY.NEW_QUESTION' | translate}}</span>
                </div>
            </mat-panel-title>
            <div class="d-flex justify-content-end"
                *ngIf="!isNew  && !onlyUpdate">
                <button mat-button
                    (click)="onDelete($event)"
                    class="mr-3">{{'COMMON.LABEL.DELETE' | translate}} <mat-icon>delete</mat-icon></button>
            </div>
        </mat-expansion-panel-header>
        <div class="m-3 d-flex flex-wrap">
            <div class="mx-3 w-100">
                <div class="row-1">
                    <mat-form-field appearance="fill"
                        class="w-100">
                        <mat-label>{{'SURVEY.QUESTION_CATEGORY'| translate}}</mat-label>
                        <mat-select [(ngModel)]="question.categoryId"
                            [disabled]="onlyUpdate">
                            <mat-option *ngFor="let category of categories"
                                [value]="category.id">{{category.name}}</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div class="row-1">
                    <mat-form-field appearance="fill"
                        class="col-sm-24 col-md-20 col-lg-15 px-0">
                        <mat-label>{{'COMMON.LABEL.TITLE' | translate}}</mat-label>
                        <input matInput
                            [(ngModel)]="question.title">
                    </mat-form-field>
                </div>
                <div class="row-1">
                    <mat-form-field appearance="fill"
                        class="w-100">
                        <mat-label>{{'COMMON.LABEL.DESCRIPTION' | translate}}</mat-label>
                        <textarea matInput
                            rows="4"
                            [(ngModel)]="question.description"></textarea>
                    </mat-form-field>
                </div>
                <div clas="row-1">
                    <p>{{'COMMON.LABEL.QUESTION_WEIGHT' | translate}}</p>
                </div>
                <div class="row-1">
                    <div class="d-flex justify-content-between col-sm-24 col-md-20 col-lg-15 px-0">
                        <ng-container *ngFor="let weight of question?.weights;">
                            <div class="col-7 px-0">
                                <mat-form-field appearance="fill"
                                    class="w-100">
                                    <mat-label>{{'SUB_ROLES.' + weight.subRole | translate}}</mat-label>
                                    <mat-select [(ngModel)]="weight.weight"
                                        [disabled]="onlyUpdate">
                                        <mat-option *ngFor="let n of weightValues"
                                            [value]="n">{{n}}</mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </div>
                        </ng-container>
                    </div>
                </div>
            </div>
            <div class="d-flex justify-content-end w-100"
                *ngIf="isNew">
                <button mat-button
                    (click)="onReset()"
                    class="mr-3">
                    {{'COMMON.LABEL.RESET' | translate}}
                </button>
                <button mat-flat-button
                    color="primary"
                    [disabled]="!isNewQuestionValid()"
                    (click)="onAdd()">
                    {{'COMMON.LABEL.ADD_EXPERTISE' | translate}}
                </button>
            </div>
        </div>
    </mat-expansion-panel>
</mat-accordion>