import { SuperModel } from './super-model.model';
import { PharmacyStatistics } from './pharmacy-statistics.model';
import { Assessment } from './assessment.model';

export class Pharmacy extends SuperModel {
  code: string;
  pdcCode: string;
  name: string;
  isActive: boolean;
  kamId: number;
  kamCode: string;
  kamName: string;
  tdfId: number;
  tdfCode: string;
  tdfName: string;
  assessments: Assessment[];
  pharmacyStats: PharmacyStatistics[];

  subRoleId: number;
  score: number;

  constructor(data: any) {
    super(data);

    if (data.pharmacyStats) {
      this.pharmacyStats = data.pharmacyStats.map(stat => new PharmacyStatistics(stat));
    }
    if (data.assessments) {
      this.assessments = data.assessments.map(assessment => new Assessment(assessment));
    }
  }

  get serialised() {
    return {};
    // return {
    //   id: this.id,
    //   code: this.code,
    //   pdcCode: this.pdcCode,
    //   name: this.name,
    //   isActive: this.isActive,
    //   kamId: this.kamId,
    //   kamCode: this.kamCode,
    //   kamName: this.kamName,
    //   tdfId: this.tdfId,
    //   tdfCode: this.tdfCode,
    //   tdfName: this.tdfName,
    //   pharmacyStats: this.pharmacyStats.map(item => item.serialised),
    //   assessments: this.assessments.map(item => item.serialised),
    // };
  }
}
