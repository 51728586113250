import { Injectable, Injector } from '@angular/core';
import { BitfApiService } from '@bitf/services/api/bitf-api.service';
import { BitfFormItemConfig } from '@common/libs/bitforce/core/models';
import { ONE_DAY_MS } from '@common/libs/bitforce/utils/bitf-dates.utils';
import { TranslateService } from '@ngx-translate/core';
import { User } from '@web/core/models';
import { Roles } from '@web/enums';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { UsersService } from './users.service';

@Injectable({
  providedIn: 'root',
})
export class PharmaciesService extends BitfApiService {
  constructor(
    public injector: Injector,
    private translateService: TranslateService,
    private usersService: UsersService
  ) {
    // super('pharmacies', injector, environment.mockApiUrl);
    super('pharmacies', injector);
  }

  getFormItemsConfig(): Observable<BitfFormItemConfig> {
    return this.usersService
      .get<User>({
        filter: `internalCode ne null`,
      })
      .pipe(
        map(res => {
          const supervisors = res.content;
          return new BitfFormItemConfig({
            name: {
              metadata: {
                filter: {
                  expression: `contains(tolower(name), '{{keyword}}')`,
                  conjunction: 'and',
                },
              },
              label: this.translateService.instant('COMMON.LABEL.PHARMACY_NAME'),
              isEditable: true,
              isVisible: true,
              isRequired: false,
              setDefault: false,
              validators: [
                {
                  key: 'maxLength',
                  params: [32],
                },
              ],
              tooltip: this.translateService.instant('COMMON.LABEL.PHARMACY_NAME'),
              type: 'text',
              default: null,
            },
            code: {
              metadata: {
                filter: {
                  expression: `contains(tolower(code), '{{keyword}}')`,
                  conjunction: 'and',
                },
              },
              label: this.translateService.instant('COMMON.LABEL.PHARMACY_CODE'),
              isEditable: true,
              isVisible: true,
              isRequired: false,
              setDefault: false,
              validators: [
                {
                  key: 'maxLength',
                  params: [32],
                },
              ],
              tooltip: this.translateService.instant('COMMON.LABEL.PHARMACY_CODE'),
              type: 'text',
              default: null,
            },
            pdcCode: {
              metadata: {
                filter: {
                  expression: `contains(tolower(pdcCode), '{{keyword}}')`,
                  conjunction: 'and',
                },
              },
              label: this.translateService.instant('COMMON.LABEL.PDC_CODE'),
              isEditable: true,
              isVisible: true,
              isRequired: false,
              setDefault: false,
              validators: [
                {
                  key: 'maxLength',
                  params: [32],
                },
              ],
              tooltip: this.translateService.instant('COMMON.LABEL.PDC_CODE'),
              type: 'text',
              default: null,
            },
            supervisor: {
              metadata: {
                filter: {
                  expression: `kamCode eq '{{keyword}}'`,
                  conjunction: 'and',
                },
              },
              options: supervisors.map(supervisor => {
                return {
                  id: supervisor.id,
                  label: supervisor.fullName,
                  metadata: null,
                };
              }),
              type: 'select',
              label: this.translateService.instant('COMMON.LABEL.SUPERVISOR'),
              isEditable: true,
              isVisible: true,
              isRequired: false,
              setDefault: true,
              validators: null,
              tooltip: this.translateService.instant('COMMON.LABEL.SUPERVISOR'),
            },
            latestAssessmentFrom: {
              metadata: {
                filter: {
                  expression: 'latestAssessmentDate gt {{keyword}}',
                  conjunction: 'and',
                },
              },
              label: this.translateService.instant('COMMON.LABEL.FROM'),
              isEditable: true,
              isVisible: true,
              isRequired: false,
              setDefault: false,
              tooltip: this.translateService.instant('COMMON.LABEL.FROM'),
              type: 'date',
              default: null,
            },
            latestAssessmentTo: {
              metadata: {
                filter: {
                  expression: 'latestAssessmentDate lt {{keyword}}',
                  conjunction: 'and',
                },
                hourOfTheDayMs: ONE_DAY_MS - 1,
              },
              label: this.translateService.instant('COMMON.LABEL.TO'),
              isEditable: true,
              isVisible: true,
              isRequired: false,
              setDefault: false,
              tooltip: this.translateService.instant('COMMON.LABEL.TO'),
              type: 'date',
              default: null,
            },
            status: {
              metadata: {
                filter: {
                  expression: `isActive eq {{keyword}}`,
                  conjunction: 'and',
                },
              },
              options: [
                {
                  id: 'true',
                  label: this.translateService.instant('COMMON.LABEL.ACTIVE'),
                  metadata: null,
                },
                {
                  id: 'false',
                  label: this.translateService.instant('COMMON.LABEL.DISABLED'),
                  metadata: null,
                },
              ],
              type: 'select',
              label: this.translateService.instant('COMMON.LABEL.STATUS'),
              isEditable: true,
              isVisible: true,
              isRequired: false,
              setDefault: true,
              validators: null,
              tooltip: this.translateService.instant('COMMON.LABEL.STATUS'),
            },
          });
        })
      );
  }
}
