import { bitfToTranslate } from '@bitf/utils/bitf-translate.utils';

export const CONSTANTS = {
  META_TAGS: {
    TITLE: {
      DEFAULT_TITLE: bitfToTranslate('COMMON.META_TAGS.DEFAULT_TITLE'),
      POSTFIX: bitfToTranslate('COMMON.META_TAGS.TITLE_POSTFIX'),
    },
    DESCRIPTION: bitfToTranslate('COMMON.META_TAGS.DESCRIPTION'),
  },
  SUPPORTED_LANGUAGES: [
    { code: 'EN', label: 'English' },
    { code: 'IT', label: 'Italiano' },
  ],
  DEFAULT_LANGUAGE: { code: 'EN', label: 'English' },
  LOCAL_STORAGE_VERSION: 1,
};
