import { CONSTANTS as COMMON_CONSTANTS } from '@common/constants/common.constants';
// tslint:disable-next-line:max-line-length
import { BitfMatOkCancelDialogComponent } from '@common/libs/bitforce/core/components/ui/ok-cancel-dialog/material/bitf-mat-ok-cancel-dialog.component';
import { bitfToTranslate } from '@common/libs/bitforce/utils/bitf-translate.utils';
import { BITF_VALIDATION_MESSAGES } from '@constants';

export const WEB_CONSTANTS = {
  fullScreenMessageComponent: null,
  okCancelDialogComponent: BitfMatOkCancelDialogComponent,
  pageSizeOptions: [10, 20, 50, 80],
  COLOR_PINK: 'rgba(240,150,213, 1)',
  COLOR_CYAN: 'rgba(136,192,251, 1)',
  COLOR_ORANGE: 'rgba(228,108,31, 1)',
  COLOR_GREEN: 'rgba(127, 165, 53, 1)',
  COLOR_BLUE: 'rgba(17, 117, 196, 1)',
  COLOR_TRANSPARENT: 'rgba(0,0,0,0)',
  MAX_CHART_VALUE: 7,
  VALIDATION_MESSAGES: {
    ...BITF_VALIDATION_MESSAGES,
    pattern: bitfToTranslate('COMMON.VALIDATION_ERROR.PATTERN'),
  },
};

export const CONSTANTS = Object.assign(COMMON_CONSTANTS, WEB_CONSTANTS);
