import { Component, OnInit, SimpleChanges, OnChanges, Input, Output, EventEmitter } from '@angular/core';
import { Question, QuestionCategory, Weight } from '@web/core/models';
import { Roles } from '@web/enums';

@Component({
  selector: 'mra-question-card',
  templateUrl: './question-card.component.html',
  styleUrls: ['./question-card.component.scss'],
})
export class QuestionCardComponent implements OnInit {
  @Input() question: Question;
  @Input() isNew = false;
  @Input() onlyUpdate = false;
  @Input() categories: QuestionCategory[] = [];
  @Output() add: EventEmitter<Question> = new EventEmitter();
  @Output() delete: EventEmitter<Question> = new EventEmitter();
  weightValues = Array.from(Array(8).keys());

  constructor() {}

  ngOnInit() {}

  isNewQuestionValid() {
    return (
      this.question.title.length > 0 &&
      this.question.description.length > 0 &&
      this.question.categoryId != null
    );
  }

  onReset() {
    this.question = new Question({});
  }

  onAdd() {
    if (this.isNewQuestionValid()) {
      this.add.emit(this.question);
      this.onReset();
    }
  }

  onDelete(evt: Event) {
    evt.stopPropagation();
    this.delete.emit(this.question);
  }
}
