import { Injectable, Injector } from '@angular/core';
import { Observable, of, pipe } from 'rxjs';
import { BitfApiService } from '@bitf/services/api/bitf-api.service';
import { Roles } from '@web/enums';
import { TranslateService } from '@ngx-translate/core';
import { BitfFormItemConfig } from '@common/libs/bitforce/core/models';
import { UsersService } from './users.service';
import { StoreService } from '../store.service';
import { map } from 'rxjs/operators';
import { User } from '@web/core/models';
import { ONE_DAY_MS } from '@common/libs/bitforce/utils/bitf-dates.utils';

@Injectable({
  providedIn: 'root',
})
export class AssessmentService extends BitfApiService {
  constructor(
    public injector: Injector,
    private translateService: TranslateService,
    private usersService: UsersService,
    private storeService: StoreService
  ) {
    super('assessments', injector);
  }

  getFormItemsConfigHistorical(pharmacyId: number): Observable<BitfFormItemConfig> {
    return this.usersService
      .get<User>({
        filter: `pharmacyId eq ${pharmacyId} and id ne ${this.storeService.store.user.id} and (ProfileId eq 'Collaboratore' OR ProfileId eq 'CollaboratoreADV')`,
      })
      .pipe(
        map(res => {
          const collaborators = res.content;
          return new BitfFormItemConfig({
            collaborator: {
              metadata: {
                filter: {
                  expression: 'user/id eq {{keyword}}',
                  conjunction: 'and',
                },
              },
              options: collaborators.map(collaborator => {
                return {
                  id: collaborator.id,
                  label: collaborator.fullName,
                  metadata: null,
                };
              }),
              type: 'select',
              label: this.translateService.instant('COMMON.LABEL.COLLABORATOR'),
              isEditable: true,
              isVisible: true,
              isRequired: false,
              setDefault: true,
              validators: null,
              tooltip: this.translateService.instant('COMMON.LABEL.COLLABORATOR'),
            },
            role: {
              metadata: {
                filter: {
                  expression: 'user/subRoleId eq {{keyword}}',
                  conjunction: 'and',
                },
              },
              options: Object.keys(Roles)
                .filter(key => !isNaN(Number(Roles[key])))
                .map(role => {
                  return {
                    id: Roles[role],
                    label: this.translateService.instant('SUB_ROLES.' + role),
                    metadata: null,
                  };
                }),
              type: 'select',
              label: this.translateService.instant('COMMON.LABEL.ROLE'),
              isEditable: true,
              isVisible: true,
              isRequired: false,
              setDefault: true,
              validators: null,
              tooltip: this.translateService.instant('COMMON.LABEL.ROLE'),
            },
            assessmentDateFrom: {
              metadata: {
                filter: {
                  expression: 'assessmentDate ge {{keyword}}',
                  conjunction: 'and',
                },
              },
              label: this.translateService.instant('COMMON.LABEL.FROM'),
              isEditable: true,
              isVisible: true,
              isRequired: false,
              setDefault: false,
              tooltip: this.translateService.instant('COMMON.LABEL.FROM'),
              type: 'date',
              default: null,
            },
            assessmentDateTo: {
              metadata: {
                filter: {
                  expression: 'assessmentDate le {{keyword}}',
                  conjunction: 'and',
                },
                hourOfTheDayMs: ONE_DAY_MS - 1,
              },
              label: this.translateService.instant('COMMON.LABEL.TO'),
              isEditable: true,
              isVisible: true,
              isRequired: false,
              setDefault: false,
              tooltip: this.translateService.instant('COMMON.LABEL.TO'),
              type: 'date',
              default: null,
            },
            startDateFrom: {
              metadata: {
                filter: {
                  expression: 'startDate ge {{keyword}}',
                  conjunction: 'and',
                },
              },
              label: this.translateService.instant('COMMON.LABEL.FROM'),
              isEditable: true,
              isVisible: true,
              isRequired: false,
              setDefault: false,
              tooltip: this.translateService.instant('COMMON.LABEL.FROM'),
              type: 'date',
              default: null,
            },
            startDateTo: {
              metadata: {
                filter: {
                  expression: 'startDate le {{keyword}}',
                  conjunction: 'and',
                },
                hourOfTheDayMs: ONE_DAY_MS - 1,
              },
              label: this.translateService.instant('COMMON.LABEL.TO'),
              isEditable: true,
              isVisible: true,
              isRequired: false,
              setDefault: false,
              tooltip: this.translateService.instant('COMMON.LABEL.TO'),
              type: 'date',
              default: null,
            },
            endDateFrom: {
              metadata: {
                filter: {
                  expression: 'endDate ge {{keyword}}',
                  conjunction: 'and',
                },
              },
              label: this.translateService.instant('COMMON.LABEL.FROM'),
              isEditable: true,
              isVisible: true,
              isRequired: false,
              setDefault: false,
              tooltip: this.translateService.instant('COMMON.LABEL.FROM'),
              type: 'date',
              default: null,
            },
            endDateTo: {
              metadata: {
                filter: {
                  expression: 'endDate le {{keyword}}',
                  conjunction: 'and',
                },
                hourOfTheDayMs: ONE_DAY_MS - 1,
              },
              label: this.translateService.instant('COMMON.LABEL.TO'),
              isEditable: true,
              isVisible: true,
              isRequired: false,
              setDefault: false,
              tooltip: this.translateService.instant('COMMON.LABEL.TO'),
              type: 'date',
              default: null,
            },
          });
        })
      );
  }

  getFormItemsConfig(): Observable<BitfFormItemConfig> {
    return of(
      new BitfFormItemConfig({
        fullName: {
          metadata: {
            filter: {
              expression: `contains(tolower(fullName), tolower('{{keyword}}'))`,
              conjunction: 'and',
            },
          },
          label: this.translateService.instant('COMMON.LABEL.NAME_AND_SURNAME'),
          isEditable: true,
          isVisible: true,
          isRequired: false,
          setDefault: false,
          validators: [
            {
              key: 'maxLength',
              params: [32],
            },
          ],
          tooltip: this.translateService.instant('COMMON.LABEL.NAME_AND_SURNAME'),
          type: 'text',
          default: null,
        },
        role: {
          metadata: {
            filter: {
              expression: 'subRoleId eq {{keyword}}',
              conjunction: 'and',
            },
          },
          options: Object.keys(Roles)
            .filter(key => !isNaN(Number(Roles[key])))
            .map(role => {
              return {
                id: Roles[role],
                label: this.translateService.instant('SUB_ROLES.' + role),
                metadata: null,
              };
            }),
          type: 'select',
          label: this.translateService.instant('COMMON.LABEL.ROLE'),
          isEditable: true,
          isVisible: true,
          isRequired: false,
          setDefault: true,
          validators: null,
          tooltip: this.translateService.instant('COMMON.LABEL.ROLE'),
        },
        assessmentDateFrom: {
          metadata: {
            filter: {
              expression: 'lastAssessmentDate ge {{keyword}}',
              conjunction: 'and',
            },
          },
          label: this.translateService.instant('COMMON.LABEL.FROM'),
          isEditable: true,
          isVisible: true,
          isRequired: false,
          setDefault: false,
          tooltip: this.translateService.instant('COMMON.LABEL.FROM'),
          type: 'date',
          default: null,
        },
        assessmentDateTo: {
          metadata: {
            filter: {
              expression: 'lastAssessmentDate le {{keyword}}',
              conjunction: 'and',
            },
            hourOfTheDayMs: ONE_DAY_MS - 1,
          },
          label: this.translateService.instant('COMMON.LABEL.TO'),
          isEditable: true,
          isVisible: true,
          isRequired: false,
          setDefault: false,
          tooltip: this.translateService.instant('COMMON.LABEL.TO'),
          type: 'date',
          default: null,
        },
      })
    );
  }
}
