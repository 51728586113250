<div class="mb-3">
    <div *ngFor="let row of legend">
        <ng-container *ngTemplateOutlet="legendRow; context: row">
        </ng-container>
    </div>
</div>
<div class="d-flex w-100">
    <chart *ngIf="data"
        class="w-100"
        [type]="data.type"
        [data]="data.data"
        [options]="data.options"></chart>
</div>


<ng-template #legendRow
    let-color="color"
    let-label="label">
    <div class="row mt-3 px-0 px-md-3 px-lg-5 align-items-center">
        <div class="col-auto">
            <span class="square-color"
                [ngStyle]="{'background-color': color}"></span>
        </div>
        <div class="col-19">
            {{label}}
        </div>
    </div>
</ng-template>