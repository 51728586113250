import { Injector } from '@angular/core';

import { BitfTryCatch } from '@bitf/core/decorators/bitf-try-catch.decorator';

import { BitfAuthService } from '../bitf-auth.service';
import { IBitfJwtTokenMetadata, IBitfJwtLogin } from './bitf-jwt-auth.interface';

export abstract class BitfJwtAuthService extends BitfAuthService<IBitfJwtLogin, IBitfJwtTokenMetadata> {
  constructor(protected injector: Injector) {
    super(injector);
  }

  static decodeToken(loginResponse: IBitfJwtLogin): IBitfJwtTokenMetadata {
    if (!loginResponse) {
      return undefined;
    }
    const tokenParts = loginResponse.encodedToken.split('.');
    const decodedToken = JSON.parse(atob(tokenParts[1]));

    Object.assign(decodedToken, {
      token: loginResponse.encodedToken,
      expiresAt: decodedToken.exp * 1000,
      iat: decodedToken.iat * 1000,
      exp: decodedToken.exp * 1000,
    });

    return decodedToken as IBitfJwtTokenMetadata;
  }

  signIn(loginResponse: IBitfJwtLogin) {
    super.signIn(loginResponse);
  }

  @BitfTryCatch()
  decodeToken(loginResponse: IBitfJwtLogin): IBitfJwtTokenMetadata {
    return BitfJwtAuthService.decodeToken(loginResponse);
  }
}
