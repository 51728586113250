import { Injectable } from '@angular/core';
import { filter } from 'rxjs/operators';
import { Subject } from 'rxjs';

import { BitfTouchEventSnapshot, BitfTouchEvent } from '../../core/models';
import { EBitfTouchEventType } from '../../enums';

@Injectable({
  providedIn: 'root',
})
export class BitfTouchEventsService {
  events: BitfTouchEvent[] = [];
  public readonly touchEvent$ = new Subject<BitfTouchEvent>();

  private touchEventSnapshots: BitfTouchEventSnapshot[] = [];
  private intervalId;
  protected timeInterval = 200;

  constructor() {}

  /**
   * Utility function to listen only to a certain event
   * @param eventId Id of the event
   */
  selectEvent(eventId: string) {
    return this.touchEvent$.pipe(filter(item => item.id === eventId));
  }

  addItem(key: string) {
    if (this.touchEventSnapshots.some(item => item.triggerItemId === key)) {
      return;
    }
    const touchEventSnapshot = new BitfTouchEventSnapshot({ triggerItemId: key });

    // NOTE: Add all events related to the pressed item
    touchEventSnapshot.events = this.events
      .filter(event => event.triggers.includes(touchEventSnapshot.triggerItemId))
      // NOTE: we've to shallo clone the object because we need to mutate it (ex isNotfied prop)
      .map(event => new BitfTouchEvent(event));
    touchEventSnapshot.startTime = Date.now();

    this.touchEventSnapshots.push(touchEventSnapshot);

    if (!this.intervalId) {
      this.processSnapshot();
      this.intervalId = setInterval(() => {
        this.processSnapshot();
      }, this.timeInterval);
    }
  }

  removeItem(key: string) {
    const touchEventSnapshotIndex = this.touchEventSnapshots.findIndex(item => item.triggerItemId === key);
    const touchEventSnapshot = this.touchEventSnapshots[touchEventSnapshotIndex];
    touchEventSnapshot.endTime = Date.now();

    const lastUpTouchEvent = touchEventSnapshot.events
      .filter(
        event =>
          event.eventType === EBitfTouchEventType.UP && event.milliseconds <= touchEventSnapshot.elapsedTime
      )
      .sort((event1, event2) => {
        return (event1.milliseconds - event2.milliseconds) * -1;
      })[0];

    if (lastUpTouchEvent) {
      this.emitEvent(lastUpTouchEvent);
    }

    this.touchEventSnapshots.splice(touchEventSnapshotIndex, 1);
    if (this.touchEventSnapshots.length === 0) {
      clearInterval(this.intervalId);
      this.intervalId = undefined;
    }
  }

  private processSnapshot() {
    this.touchEventSnapshots.forEach(touchEventSnapshot => {
      touchEventSnapshot.elapsedTime = Date.now() - touchEventSnapshot.startTime;

      touchEventSnapshot.events.forEach(touchEvent => {
        if (touchEventSnapshot.elapsedTime >= touchEvent.milliseconds) {
          this.notifyEvent(touchEvent);
          if (touchEvent.eventType === EBitfTouchEventType.DOWN) {
            this.emitEvent(touchEvent);
          }
        }
      });
    });
  }

  private emitEvent(touchEvent: BitfTouchEvent) {
    this.touchEvent$.next(touchEvent);

    // Remove this event from all TouchItems
    this.touchEventSnapshots.forEach(touchEventSnapshot => {
      touchEventSnapshot.events.forEach(event => {
        if (event.id === touchEvent.id) {
          const index = touchEventSnapshot.events.indexOf(event);
          touchEventSnapshot.events.splice(0, index + 1);
        }
      });
    });
  }

  private notifyEvent(touchEvent: BitfTouchEvent) {
    if (touchEvent.isNotified) {
      return;
    }
    touchEvent.isNotified = true;

    if (touchEvent.sound) {
      new Audio(touchEvent.sound).play();
    }
    if (touchEvent.vibration) {
      this.vibrate(touchEvent.vibration);
    }
  }

  private vibrate(vibrationConfig: number | number[]) {
    if (window.navigator.vibrate) {
      window.navigator.vibrate(vibrationConfig);
    }
  }
}
