import { SuperModel } from './super-model.model';
import { Question } from '@models';

export class Survey extends SuperModel {
  questions: Question[] = [];
  creationDate: Date;
  adoptionDate: Date;
  status: 'PUBLISH' | 'ACTIVE' | 'INACTIVE';

  constructor(data: Partial<Survey> = {}) {
    super(data);

    if (data.questions) {
      this.questions = data.questions.map(question => new Question(question));
      this.questions.sort((q1, q2) => q1.position - q2.position);
    }
  }

  get serialised() {
    return {
      id: this.id,
      questions: this.questions.map(q => q.serialised),
      creationDate: this.creationDate,
      adoptionDate: this.adoptionDate,
      status: this.status,
    };
  }
}
