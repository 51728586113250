import { OnInit, Input, Output, EventEmitter, Injector, OnChanges, Component } from '@angular/core';
import { FormGroup, FormArray } from '@angular/forms';

import { TranslateService } from '@ngx-translate/core';
import { debounce, distinctUntilChanged, filter } from 'rxjs/operators';
import { timer } from 'rxjs';

import { BitfFormItem } from '@bitf/core/models/bitf-form-item.model';
import { numberMask } from '@bitf/utils/bitf-form.utils';
import { BitfFormControl } from '@bitf/core/form';

import { IBitfUiRoleManagerConfig } from '@interfaces';

// tslint:disable-next-line:max-line-length
import { ERoleMode, EBitfFormControlValidatorsKeys } from '@enums';
import { UiRoleManagerService } from '@services';

import { IBitfFormItemComponentConfig } from './bitf-form-item.interface';

@Component({
  selector: 'bitf-form-item',
  template: '',
})
export abstract class BitfFormItemComponent implements OnInit, OnChanges {
  @Input() formItem: BitfFormItem;

  @Input() form: FormGroup | FormArray;

  @Input() formItemControlName: string | number;

  @Input() componentConfig: IBitfFormItemComponentConfig = {};

  @Input() showLoader: boolean;

  @Input() minDateField: string;

  @Output() valueChanges: EventEmitter<any> = new EventEmitter();

  @Output() selectionChange: EventEmitter<any> = new EventEmitter();

  hiddenUIRole: IBitfUiRoleManagerConfig;

  formControl: BitfFormControl = new BitfFormControl('');
  savedFormControlValue: any;

  minDate?: Date;
  maxDate?: Date;

  numberMask = numberMask();

  uiRoleManagerService: UiRoleManagerService;
  translateService: TranslateService;

  constructor(public injector: Injector) {
    this.uiRoleManagerService = injector.get<UiRoleManagerService>(UiRoleManagerService);
    this.translateService = injector.get<TranslateService>(TranslateService);
  }

  ngOnInit() {
    if (!this.formItem) {
      return;
    }

    if (this.formItem.uiRole) {
      Object.assign(this.componentConfig, { uiRole: this.formItem.uiRole });
    }

    if (this.componentConfig && this.componentConfig.uiRole) {
      this.hiddenUIRole = {
        ...this.componentConfig.uiRole,
        mode: ERoleMode.HIDDEN,
      };
    }

    if (this.form instanceof FormArray) {
      this.formControl = this.form.at(this.formItemControlName as number) as BitfFormControl;
    } else {
      this.formControl = this.form.get(this.formItemControlName as string) as BitfFormControl;
    }

    this.initForItem();
    this.initValidation();
    this.setDefault();
    this.onDisabledChange();
  }

  ngOnChanges(changes): void {
    const shoudCheckIsDisabled =
      changes.formItem && !changes.formItem.firstChange && changes.formItem.currentValue;
    if (shoudCheckIsDisabled) {
      this.onDisabledChange();
    }
  }

  onSelectionChange(event) {
    this.selectionChange.emit(event);
  }

  canAction(): boolean {
    if (this.componentConfig.uiRole) {
      return this.uiRoleManagerService.canI(this.componentConfig.uiRole.action);
    }
    return true;
  }

  protected initForItem() {
    if (this.formItem.numberMask) {
      this.numberMask = numberMask(this.formItem.numberMask);
    }

    if (this.componentConfig && this.componentConfig.numberMask) {
      this.numberMask = numberMask(this.componentConfig.numberMask);
    }

    if (this.minDateField) {
      this.form.get(this.minDateField).valueChanges.subscribe(value => {
        this.reloadDate(new Date(value));
      });
    }

    if (this.formItem.type === 'autocomplete') {
      this.formControl.valueChanges
        .pipe(
          debounce(() => timer(500)),
          distinctUntilChanged(),
          filter(value => value.length >= 3 || value.length === 0)
        )
        .subscribe(result => {
          this.valueChanges.emit(result);
        });
    }
  }

  protected initValidation() {
    this.formItem.validators = this.formItem.validators ? this.formItem.validators : [];

    // NOTE: this is left for backwards compatibility, validators will be specified in the
    // valiators: [{key: EBitfFormControlValidatorsKeys, params: any }] prop
    if (this.formItem.isRequired) {
      this.formItem.validators.push({ key: EBitfFormControlValidatorsKeys.required });
    }

    if (this.formItem.validators && this.formItem.validators.length) {
      this.formControl.setDynamicValidator(this.formItem.validators);
    }
  }

  protected setDefault() {
    if (
      this.formItem.type !== 'select' &&
      this.formItem.default !== null &&
      !this.formItem.default !== undefined &&
      this.formControl.value === null &&
      this.formItem.setDefault
    ) {
      this.formControl.setValue(this.formItem.default);
    }
  }

  protected onDisabledChange() {
    if (!this.formControl) {
      return;
    }

    if (this.formItem.isDisabled && !this.formControl.disabled) {
      this.savedFormControlValue = this.formControl.value;
      this.formControl.disable();
    } else if (!this.formItem.isDisabled && this.formControl.disabled) {
      this.formControl.enable();
      this.formControl.setValue(this.savedFormControlValue);
    }
  }

  protected reloadDate(startDate: Date) {
    const currentDate = this.formControl.value;
    if (startDate > new Date(currentDate)) {
      this.formControl.patchValue(startDate);
    }
  }
}
