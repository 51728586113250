import { SuperModel } from './super-model.model';
import { QuantitativeSkill, QualitativeSkill, User, YieldData } from '@models';

export class Assessment extends SuperModel {
  startDate: Date;
  endDate: Date;
  assessmentDate: Date;
  counter: number;
  score: number;
  isDraft: boolean;
  sharedWithUser: boolean;
  user: User;
  userId: number;
  surveyId: number;
  weightedAverage: number;
  notes: string;
  yeldData: YieldData;
  quantitativeSkills: QuantitativeSkill[];
  qualitativeSkills: QualitativeSkill[];

  constructor(data: Partial<Assessment>) {
    super(data);

    if (data.startDate) {
      this.startDate = new Date(data.startDate);
    }
    if (data.endDate) {
      this.endDate = new Date(data.endDate);
    }
    if (data.assessmentDate) {
      this.assessmentDate = new Date(data.assessmentDate);
    }

    if (data.user) {
      this.user = new User(data.user);
    }

    if (data.yeldData) {
      this.yeldData = new YieldData(data.yeldData);
    }

    if (data.quantitativeSkills) {
      this.quantitativeSkills = data.quantitativeSkills.map(
        quantitativeSkill => new QuantitativeSkill(quantitativeSkill)
      );
    }

    if (data.qualitativeSkills) {
      this.qualitativeSkills = data.qualitativeSkills
        .map(qualitativeSkill => new QualitativeSkill(qualitativeSkill))
        .sort((a, b) => a.question.position - b.question.position);
    }
  }

  get serialised() {
    return {
      id: this.id,
      startDate: this.startDate,
      endDate: this.endDate,
      assessmentDate: this.assessmentDate,
      score: this.score,
      isDraft: this.isDraft,
      sharedWithUser: this.sharedWithUser,
      userId: this.userId,
      weightedAverage: this.weightedAverage,
      notes: this.notes,
      surveyId: this.surveyId,
      quantitativeSkills: this.quantitativeSkills && this.quantitativeSkills.map(item => item.serialised),
      qualitativeSkills: this.qualitativeSkills && this.qualitativeSkills.map(item => item.serialised),
    };
  }
}
