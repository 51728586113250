import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-welcome-desktop',
  templateUrl: './welcome-desktop.component.html',
  styleUrls: ['./welcome-desktop.component.scss'],
})
export class WelcomeDesktopComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
