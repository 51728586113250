import { Component, OnInit, Input, OnChanges } from '@angular/core';
import { IChart } from '@web/interfaces';

@Component({
  selector: 'mra-doughnut-chart',
  templateUrl: './doughnut-chart.component.html',
  styleUrls: ['./doughnut-chart.component.scss'],
})
export class DoughnutChartComponent implements OnInit, OnChanges {
  @Input() data: IChart;

  legend: { color: string; label: string }[];

  constructor() {}

  ngOnInit(): void {}

  ngOnChanges() {
    this.legend = [];
    if (this.data?.data?.labels?.length > 0) {
      this.data?.data.labels.forEach((element, index) => {
        this.legend.push({ color: this.data?.data.datasets[0].backgroundColor[index], label: element });
      });
    }
  }
}
