<ng-container>
    <h1>{{'COMMON.LABEL.ASSESSMENT.YIELD_MATRIX' | translate}}</h1>
    <p>{{'COMMON.LABEL.ASSESSMENT.YIELD_MATRIX_SUBTITLE' | translate}}</p>

    <div class="d-flex row justify-content-around py-3">
        <div class="col-3"></div>
        <mat-card class="col-3">
            <mat-card-content>
                <span>A+C
                </span>
                <h1 class="pb-2">{{yieldData?.aPlusC | number:'1.1-1'}}</h1>
            </mat-card-content>
        </mat-card>
        <mat-card class="col-3 mx-3">
            <mat-card-content>
                <span>P</span>
                <h1 class="pb-2">{{yieldData?.p | number:'1.1-1'}}</h1>
            </mat-card-content>
        </mat-card>
        <div class="col-3"></div>
    </div>

    <div class="w-75 m-auto py-3 text-center">
        <div class="row">
            <div class="col-4 border">
                <div class="row h-100">
                    <div class="col my-auto">{{'COMMON.LABEL.YIELD_MATRIX.MVC_AC' | translate}}</div>
                </div>
            </div>
            <div class="col-20">
                <div class="row">
                    <div class="col-6 border-top border-right py-1">
                        <div class="row h-100">
                            <div class="col my-auto">{{'COMMON.LABEL.YIELD_MATRIX.OVER_GOALS' | translate}}
                            </div>
                        </div>
                    </div>

                    <div class="col-6 border-top border-right py-1">
                        <div class="row sm-row">
                            <div class="col">C</div>
                        </div>
                        <div class="row sm-row">
                            <div class="col">
                                <mat-icon *ngIf="yieldData?.result === 'C'">done</mat-icon>
                            </div>
                        </div>
                    </div>
                    <div class="col-6 border-top border-right py-1">
                        <div class="row sm-row">
                            <div class="col">B</div>
                        </div>
                        <div class="row sm-row">
                            <div class="col">
                                <mat-icon *ngIf="yieldData?.result === 'B'">done</mat-icon>
                            </div>
                        </div>
                    </div>
                    <div class="col-6 border-top border-right py-1">
                        <div class="row sm-row">
                            <div class="col">A</div>
                        </div>
                        <div class="row sm-row">
                            <div class="col">
                                <mat-icon *ngIf="yieldData?.result === 'A'">done</mat-icon>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-6 border-top border-right py-1">
                        <div class="row h-100">
                            <div class="col my-auto">{{'COMMON.LABEL.YIELD_MATRIX.IN_GOALS' | translate}}
                            </div>
                        </div>
                    </div>
                    <div class="col-6 border-top border-right py-1">
                        <div class="row sm-row">
                            <div class="col">F</div>
                        </div>
                        <div class="row sm-row">
                            <div class="col">
                                <mat-icon *ngIf="yieldData?.result === 'F'">done</mat-icon>
                            </div>
                        </div>
                    </div>
                    <div class="col-6 border-top border-right py-1">
                        <div class="row sm-row">
                            <div class="col">E</div>
                        </div>
                        <div class="row sm-row">
                            <div class="col">
                                <mat-icon *ngIf="yieldData?.result === 'E'">done</mat-icon>
                            </div>
                        </div>
                    </div>
                    <div class="col-6 border-top border-right py-1">
                        <div class="row sm-row">
                            <div class="col">D</div>
                        </div>
                        <div class="row sm-row">
                            <div class="col">
                                <mat-icon *ngIf="yieldData?.result === 'D'">done</mat-icon>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-6 border-top border-right">
                        <div class="row h-100">
                            <div class="col my-auto">{{'COMMON.LABEL.YIELD_MATRIX.UNDER_GOALS' | translate}}
                            </div>
                        </div>
                    </div>
                    <div class="col-6 border-top border-right py-1">
                        <div class="row sm-row">
                            <div class="col">I</div>
                        </div>
                        <div class="row sm-row">
                            <div class="col">
                                <mat-icon *ngIf="yieldData?.result === 'I'">done</mat-icon>
                            </div>
                        </div>
                    </div>
                    <div class="col-6 border-top border-right py-1">
                        <div class="row sm-row">
                            <div class="col">H</div>
                        </div>
                        <div class="row sm-row">
                            <div class="col">
                                <mat-icon *ngIf="yieldData?.result === 'H'">done</mat-icon>
                            </div>
                        </div>
                    </div>
                    <div class="col-6 border-top border-right py-1">
                        <div class="row sm-row">
                            <div class="col">G</div>
                        </div>
                        <div class="row sm-row">
                            <div class="col">
                                <mat-icon *ngIf="yieldData?.result === 'G'">done</mat-icon>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row md-row border-top">
            <div class="col-4"></div>
            <div class="col-5 border-right"></div>
            <div class="col-5 border-right">
                <div class="row h-100">
                    <div class="col my-auto">{{'COMMON.LABEL.YIELD_MATRIX.UNDER_GOALS' | translate}}</div>
                </div>
            </div>
            <div class="col-5 border-right">
                <div class="row h-100">
                    <div class="col my-auto">{{'COMMON.LABEL.YIELD_MATRIX.IN_GOALS' | translate}}</div>
                </div>
            </div>
            <div class="col-5 border-right">
                <div class="row h-100">
                    <div class="col my-auto">{{'COMMON.LABEL.YIELD_MATRIX.OVER_GOALS' | translate}}</div>
                </div>
            </div>
        </div>
        <div class="row md-row">
            <div class="col-4"></div>
            <div class="col-5 border-right"></div>
            <div class="col-15 border-top border-right border-bottom">
                <div class="row h-100">
                    <div class="col my-auto">{{'COMMON.LABEL.YIELD_MATRIX.MVC_P' | translate}}</div>
                </div>
            </div>
        </div>
    </div>

</ng-container>