import { BitfFormItem } from './bitf-form-item.model';

export class BitfFormItemConfig {
  [key: string]: BitfFormItem;

  constructor(data: any = {}) {
    if (data) {
      Object.keys(data).forEach(key => {
        this[key] = new BitfFormItem(data[key]);
      });
    }
  }
}
