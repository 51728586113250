import { Injectable, Injector } from '@angular/core';

import { BitfApiService } from '@bitf/services/api/bitf-api.service';

@Injectable({
  providedIn: 'root',
})
export class AdminPharmaciesStatsService extends BitfApiService {
  constructor(public injector: Injector) {
    super('vw_average_score_overviews_by_admin', injector);
  }
}
