<ng-container>
    <ng-container *ngIf="!onlyTable">
        <h1>{{'COMMON.LABEL.ASSESSMENT.QUANTITATIVE_SKILLS' | translate}}</h1>
        <p>{{'COMMON.LABEL.ASSESSMENT.QUANTITATIVE_SKILLS_SUBTITLE' | translate}}</p>
    </ng-container>

    <table class="p-3 text-center w-100">
        <thead>
            <tr>
                <th class="mat-header-cell text-center">
                    {{'COMMON.LABEL.SKILL.AREA' | translate}}
                </th>
                <th class="mat-header-cell text-center">
                    {{'COMMON.LABEL.SKILL.KEY_PERFORMANCE_INDICATOR' | translate}}
                </th>
                <th class="mat-header-cell text-center">
                    {{'COMMON.LABEL.SKILL.PHARMACIST' | translate}}
                </th>
                <th class="mat-header-cell text-center">
                    {{'COMMON.LABEL.SKILL.PHARMACIST_AVERAGE' | translate}}
                </th>
                <th class="mat-header-cell text-center">
                    {{'COMMON.LABEL.SKILL.WEIGHT' | translate}}
                </th>
            </tr>
        </thead>
        <tbody>
            <ng-container *ngFor="let skill of dataSource">
                <tr *ngFor="let question of skill.questions; let index = index">
                    <td *ngIf="index === 0"
                        [attr.rowspan]="skill.questions.length">{{skill.areaText}}</td>
                    <td>
                        {{question?.kpiText}}
                    </td>
                    <td>
                        {{question?.apotecaScore}}
                    </td>
                    <td>
                        {{question?.averageScore}}
                    </td>
                    <td>
                        {{question?.weight | percent}}
                    </td>
                </tr>
            </ng-container>
        </tbody>
    </table>
</ng-container>