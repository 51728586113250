import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CONSTANTS } from '@constants';
import { EApiCallStateNames, EApiRequestPartKeys } from '@enums';
import { IBitfApiPagination, IBitfSortOption } from '@interfaces';
import { Assessment, User } from '@models';
import { TranslateService } from '@ngx-translate/core';
import { AssessmentService, LoaderService, StoreService } from '@web/core/services';

@Component({
  selector: 'mra-assessments-list',
  templateUrl: './assessments-list.component.html',
  styleUrls: ['./assessments-list.component.scss'],
})
export class AssessmentsListComponent implements OnInit {
  @Input() dataSource: User[];
  @Input() apiCallStateName: EApiCallStateNames;
  @Input() pagination: IBitfApiPagination;
  @Input() isAssessmentManagement = true;
  @Input() actionButtonEnabled = true;

  pageSizeOptions = CONSTANTS.pageSizeOptions;
  displayedColumns: string[] = [];

  EApiRequestPartKeys = EApiRequestPartKeys;
  orderByOptions: { [key: string]: IBitfSortOption } = {
    id: {
      value: { property: 'id', direction: undefined },
      label: `${this.translateService.instant('COMMON.USER.ID')}`,
    },
    fullName: {
      value: { property: 'fullName', direction: undefined },
      label: `${this.translateService.instant('COMMON.USER.FULL_NAME')}`,
    },
    subRole: {
      value: { property: 'subRole', direction: undefined },
      label: `${this.translateService.instant('COMMON.USER.ROLE')}`,
    },
    assessmentsCount: {
      value: { property: 'assessmentsCount', direction: undefined },
      label: `${this.translateService.instant('COMMON.LABEL.ASSESSMENTS_COUNTER')}`,
    },
    lastAssessmentDate: {
      value: { property: 'lastAssessmentDate', direction: undefined },
      label: `${this.translateService.instant('COMMON.ASSESSMENT.ASSESSMENT_DATE')}`,
    },
    lastAssessmentScore: {
      value: { property: 'lastAssessmentScore', direction: undefined },
      label: `${this.translateService.instant('COMMON.ASSESSMENT.LATEST_SCORE')}`,
    },
    deleted: {
      value: { property: 'deleted', direction: undefined },
      label: `${this.translateService.instant('COMMON.LABEL.STATUS')}`,
    },
  };

  constructor(
    private translateService: TranslateService,
    private assessmentService: AssessmentService,
    private loaderService: LoaderService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    public storeService: StoreService
  ) {}

  ngOnInit(): void {
    this.displayedColumns = [
      'id',
      'fullName',
      'subRole',
      'assessmentsCount',
      'lastAssessmentDate',
      'lastAssessmentScore',
      'deleted',
      'actions',
    ];
  }

  onResume(user: User) {
    this.navigateToAssessmentEdit(user.id, user.assessmentId);
  }

  onEvaluate(userId: string) {
    this.createNewAssessmentAndNavigateToIt(userId);
  }

  onOpen(user: User) {
    if (this.isAssessmentManagement) {
      return;
    }

    const root = this.activatedRoute.snapshot.pathFromRoot[2].url[0].path;
    let path = [];
    if (root === 'admin') {
      path = ['/admin', 'pharmacies', user.pharmacyId, 'collaborators', user.id];
    } else {
      path = ['/owner', 'assessments-management', 'collaborators', user.id];
    }

    this.router.navigate(path);
  }

  userRegistryLink(element) {
    const root = '/' + this.activatedRoute.snapshot.pathFromRoot[2].url[0].path;
    return [root, 'pharmacies', element.pharmacyId, 'registry', element.id];
  }

  private createNewAssessmentAndNavigateToIt(userId: string) {
    this.loaderService.show();
    this.assessmentService
      .post<Assessment>({
        body: {
          userId,
        },
        isBodyRaw: true,
      })
      .subscribe(response => {
        const assessmentId = response.content.id;
        this.navigateToAssessmentEdit(userId, assessmentId);
      });
  }

  private navigateToAssessmentEdit(userId, assessmentId) {
    const routeNav = [];
    if (!this.isAssessmentManagement) {
      routeNav.push(...['..', 'assessments-management']);
    }
    this.router.navigate([...routeNav, 'collaborators', userId, 'assessments', assessmentId, 'edit'], {
      relativeTo: this.activatedRoute,
    });
  }
}
