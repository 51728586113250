import { Question } from '@models';
import { SuperModel } from './super-model.model';

export class QualitativeSkill extends SuperModel {
  score: number;
  notes: string;
  questionId: string;
  question: Question;
  weight: number;
  valid: boolean;

  constructor(data: Partial<QualitativeSkill>) {
    super(data);

    if (data.question) {
      this.question = new Question(data.question);
    }
  }

  get serialised() {
    return {
      id: this.id,
      score: this.score,
      notes: this.notes,
      questionId: this.questionId,
      question: this.question,
      weight: this.weight,
    };
  }
}
