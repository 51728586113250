<ng-container>
    <h1>{{'COMMON.LABEL.ASSESSMENT.QUALITATIVE_SKILLS' | translate}}</h1>
    <div class="d-flex">
        <mat-card color="primary"
            class="mat-elevation-z3">
            <mat-card-content>
                <div class="row">
                    <div class="col-18">
                        <span>{{'COMMON.LABEL.ASSESSMENT.WEIGHTED_AVERAGE' | translate}}
                        </span>
                        <h1 class="pb-2">{{assessment?.weightedAverage | number:'1.1-1'}}</h1>
                    </div>
                    <div class="col-6">
                        <mat-icon>pie_chart</mat-icon>
                    </div>
                </div>
            </mat-card-content>
        </mat-card>
        <mat-card class="mat-elevation-z3 mx-3">
            <mat-card-content>
                <span>{{'COMMON.LABEL.ASSESSMENT.LAST_EVALUATION' | translate}}</span>
                <h1 class="pb-2">{{assessment?.score | number:'1.1-1'}}</h1>
            </mat-card-content>
        </mat-card>
    </div>
    <div class="w-75 m-auto py-3 print-chart">
        <mra-radar-chart [data]="qualitativeSkills"></mra-radar-chart>
    </div>
    <div>
        <table mat-table
            [dataSource]="dataSourceSkills"
            class="mat-elevation-z3 w-100">
            <ng-container matColumnDef="id">
                <th mat-header-cell
                    *matHeaderCellDef
                    class="code-cell"> {{'COMMON.LABEL.SKILL.NUMBER' | translate}}
                </th>
                <td mat-cell
                    class="code-cell"
                    *matCellDef="let element"> {{element.position + 1}} </td>
            </ng-container>

            <ng-container matColumnDef="name">
                <th mat-header-cell
                    *matHeaderCellDef> {{'COMMON.LABEL.SKILL.NAME' | translate}}
                </th>
                <td mat-cell
                    *matCellDef="let element"> {{element.name}} </td>
            </ng-container>

            <ng-container matColumnDef="description">
                <th mat-header-cell
                    *matHeaderCellDef> {{'COMMON.LABEL.SKILL.DESCRIPTION' | translate}}
                </th>
                <td mat-cell
                    *matCellDef="let element"> {{element.description}} </td>
            </ng-container>

            <ng-container matColumnDef="note">
                <th mat-header-cell
                    *matHeaderCellDef> {{'COMMON.LABEL.NOTE' | translate}}
                </th>
                <td mat-cell
                    *matCellDef="let element"> {{element.notes}} </td>
            </ng-container>

            <ng-container matColumnDef="weight">
                <th mat-header-cell
                    *matHeaderCellDef> {{'COMMON.LABEL.SKILL.WEIGHT' | translate}}
                </th>
                <td mat-cell
                    *matCellDef="let element">
                    <div class="text-nowrap">
                        <mat-icon class="small-circle"
                            *ngFor="let i of element.weight">circle</mat-icon>
                        <mat-icon class="small-circle"
                            *ngFor="let i of Array(7 - element.weight.length)">
                            panorama_fish_eye</mat-icon>
                    </div>
                </td>
            </ng-container>

            <ng-container matColumnDef="score">
                <th mat-header-cell
                    *matHeaderCellDef> {{'COMMON.LABEL.SKILL.SCORE' | translate}}
                </th>
                <td mat-cell
                    *matCellDef="let element">
                    <div class="text-nowrap">
                        <mat-icon class="small-circle"
                            [ngClass]="element.score.length < 4 ? 'red-circle' : 'green-circle'"
                            *ngFor="let i of element.score">circle</mat-icon>
                        <mat-icon class="small-circle"
                            [ngClass]="element.score.length < 4 ? 'red-circle' : 'green-circle'"
                            *ngFor="let i of Array(7 - element.score.length)">
                            panorama_fish_eye</mat-icon>
                    </div>
                </td>
            </ng-container>

            <tr mat-header-row
                *matHeaderRowDef="displayedColumnsSkills"></tr>
            <tr mat-row
                class="highlight-row"
                *matRowDef="let row; columns: displayedColumnsSkills;"></tr>
        </table>
    </div>
    <br>
    <div>
        <mat-table [dataSource]="dataSourceOverall"
            class="mat-elevation-z3">

            <ng-container matColumnDef="name">
                <mat-cell *matCellDef="let element"
                    class="mx-3"><b>{{element.name}}</b></mat-cell>
            </ng-container>

            <ng-container matColumnDef="overallDescription">
                <mat-cell *matCellDef="let element"> {{element.description}} </mat-cell>
            </ng-container>

            <mat-row *matRowDef="let row; columns: displayedColumnsOverall;"></mat-row>
        </mat-table>
    </div>
</ng-container>