import { Component } from '@angular/core';

import { BitfSortComponent } from '../bitf-sort.component';

@Component({
  selector: 'bitf-mat-sort',
  templateUrl: './bitf-mat-sort.component.html',
  styleUrls: ['./bitf-mat-sort.component.scss'],
})
export class BitfMatSortComponent extends BitfSortComponent {}
