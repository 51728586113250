import { Injectable, Injector } from '@angular/core';

import { bitfToTranslate } from '@bitf/utils/bitf-translate.utils';

import { IBitfApiUiMessage } from '@interfaces';
import { EBitfUiMessageTarget, EBitfUiMessageType } from '@enums';
import { BITF_CONFIGS } from '@configs';
import { CONSTANTS } from '@constants';
import { BitfUiMessagesListenerService } from '../bitf-ui-messages-listener.service';

@Injectable({
  providedIn: 'root',
})
export class BitfWebUiMessagesListenerService extends BitfUiMessagesListenerService {
  constructor(protected injector: Injector) {
    super(injector);
  }

  initApiErrorsInterceptorSrategies() {
    super.initApiErrorsInterceptorSrategies();

    this.apiErrorsInterceptorStrategies['401'] = () => {
      this.dialogsService.dialog.closeAll();
      const dialog = this.dialogsService.dialog.open(CONSTANTS.okCancelDialogComponent, {
        width: '80%',
        maxWidth: '600px',
        disableClose: true,
        data: {
          title: bitfToTranslate('BITF.DIALOG.SESSION_EXPIRATION.TITLE'),
          message: bitfToTranslate('BITF.DIALOG.SESSION_EXPIRATION.DESCRIPTION'),
          okText: bitfToTranslate('BITF.LABEL.OK'),
        },
      });
      dialog.afterClosed().subscribe(() => {
        this.isSessionExpiredNotified = false;
        this.authService.signOut();
      });
    };
  }

  initUiMessagesStrategies() {
    super.initUiMessagesStrategies();

    this.uiMessagesStrategies[EBitfUiMessageTarget.DIALOG] = uiMessage => {
      const payload: IBitfApiUiMessage = uiMessage.payload;
      this.dialogsService.dialog.closeAll();
      this.dialogsService.dialog.open(CONSTANTS.okCancelDialogComponent, {
        width: '80%',
        maxWidth: '600px',
        disableClose: true,
        data: {
          title: payload.title || '',
          message: payload.message || '',
          // FIXME: these text should came form the payload
          okText: bitfToTranslate('BITF.LABEL.OK'),
        },
      });
    };

    this.uiMessagesStrategies[EBitfUiMessageTarget.ERROR_PAGE] = uiMessage => {
      const { message, title }: IBitfApiUiMessage = uiMessage.payload;
      this.router.navigate(['/error'], { queryParams: { message, title } });
    };
  }
}
