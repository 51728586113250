import { QuestionCategory, Weight } from '@models';
import { SuperModel } from './super-model.model';
import { Roles } from '@enums';

export class Question extends SuperModel {
  position: number;
  weights: Weight[];
  title: string;
  description: string;
  surveyId: string;
  score: number;
  notes: string;
  categoryId: string;
  category?: QuestionCategory;

  constructor(data: Partial<Question>) {
    super(data);

    this.weights = this.weights || [];
    this.title = this.title || '';
    this.description = this.description || '';

    const weightsById = new Set<number>();
    if (data.weights) {
      data.weights.sort((a, b) => {
        return a.subRoleId - b.subRoleId;
      });
      this.weights = data.weights.map(w => {
        weightsById.add(w.subRoleId);
        return new Weight(w);
      });
    }

    const missingRoles = Object.keys(Roles)
      .filter(key => !isNaN(Number(Roles[key])))
      .map((role: string) => Roles[role])
      .filter(roleId => !weightsById.has(roleId))
      .map(roleId => new Weight({ weight: 0, subRoleId: roleId }));

    this.weights = this.weights.concat(missingRoles);

    if (data.category) {
      this.category = new QuestionCategory(data.category);
    }
  }

  get serialised() {
    return {
      id: this.id,
      title: this.title,
      description: this.description,
      position: this.position,
      weights: this.weights && this.weights.map(w => w.serialised),
      surveyId: this.surveyId,
      score: this.score,
      notes: this.notes,
      categoryId: this.categoryId,
    };
  }
}
