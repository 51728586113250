import { Injectable, Injector } from '@angular/core';
import { BitfApiService } from '@bitf/services/api/bitf-api.service';

@Injectable({
  providedIn: 'root',
})
export class QuestionsCategoriesService extends BitfApiService {
  constructor(public injector: Injector) {
    super('questions-categories', injector);
  }
}
