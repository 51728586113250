import { SuperModel } from './super-model.model';
import { Assessment } from './assessment.model';
import { ERoles, Roles } from '@enums';

export class User extends SuperModel {
  firstName: string;
  lastName: string;
  fullName: string;
  birthDate: Date;
  lang: string;
  registrationDate: Date;
  email: string;
  profile: string;
  profileId: string;
  eProfile: EUserProfile;
  subRole: string;
  subRoleId: number;
  pharmacyId: number;
  role: ERoles;
  updatedTo: Date;
  assessments?: Assessment[];
  assessmentsCount: number;
  assessmentId: number;
  internalCode: string;
  oreContrattoAnnuale: number;
  deleted: boolean;
  dtDeleted: Date;

  constructor(data: Partial<User>) {
    super(data);
    this.subRole = Roles[this.subRoleId];

    if (data.assessments) {
      this.assessments = data.assessments.map(assessment => new Assessment(assessment));
    }

    this.assessmentsCount = data['assessments@odata.count'] || 0;
    this.eProfile = profileIdToEProfile(this.profileId);
  }

  get serialised() {
    return {};
  }

  get canViewLogs() {
    return true;
  }
}

export enum EUserProfile {
  ADMIN,
  OWNER,
  SUPERVISOR,
  COLLABORATOR,
}

function profileIdToEProfile(profileId: string) {
  switch (profileId) {
    case 'Amministratore':
      return EUserProfile.ADMIN;
    case 'SuperTitolare':
    case 'Titolare':
      return EUserProfile.OWNER;
    case 'Collaboratore':
    case 'CollaboratoreADV':
      return EUserProfile.COLLABORATOR;
    case 'AbocaAdmin':
    case 'TDF':
      return EUserProfile.SUPERVISOR;
    default:
      return EUserProfile.COLLABORATOR;
  }
}
