import { Injectable, Pipe, PipeTransform } from '@angular/core';

import { TranslateService } from '@ngx-translate/core';

import { IBitfFormControlTranslations } from './bitf-form-control-translate.interface';

@Injectable()
@Pipe({
  name: 'bitfFormControlTranslate',
})
export class BitfFormControlTranslatePipe implements PipeTransform {
  constructor(private translateService: TranslateService) {}
  // NOTE : query will have this shape, defined in bitf form control
  // this.validationErrors = {
  //   keys = [],
  //   params = {},
  // };
  transform(query: IBitfFormControlTranslations): any {
    if (!query) {
      return '';
    }
    return query.keys.map(key => this.translateService.instant(key, query.params)).join(', ');
  }
}
