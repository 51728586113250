import { Injectable, Injector } from '@angular/core';

import { initDynamicLayoutUrl, updateAppLayout } from '@bitf/utils/bitf-responsive.utils';

import { BitfAppSessionService } from '@bitf/services/app-session/bitf-app-session.service';
import { BitfTagManagerService } from '@bitf/services/tag-manager/bitf-tag-manager.service';
import { BitfLogDnaSenderService } from '@bitf/services/logger/bitf-log-dna-sender.service';

import { ResponsiveService } from '@services';
import { bitfToTranslate } from '@common/libs/bitforce/utils/bitf-translate.utils';

@Injectable({
  providedIn: 'root',
})
export class AppSessionService extends BitfAppSessionService {
  constructor(
    private bitfTagManagerService: BitfTagManagerService,
    private bitfLogDnaSenderService: BitfLogDnaSenderService,
    private responsiveService: ResponsiveService,
    protected injector: Injector,
    private logDnaSenderService: BitfLogDnaSenderService
  ) {
    super(injector);
  }

  init() {
    super.init();

    this.initBreakpointListener();
    this.responsiveService.init();
    // --------------------------

    this.bitfTagManagerService.init();
    this.bitfLogDnaSenderService.init();

    // // PWA
    // this.bitfPwaService.init();
    // this.bitfPwaService.initManifest();
    // this.bitfPwaService.initSwUpdate();
    // this.bitfPwaService.handleSwUpdate();
    // this.bitfPwaService.initBeforeInstallPrompt();
    // this.bitfPwaService.checkForBeforeInstallPromptEvent();
    // this.bitfPwaService.initOnlineChecker();
    this.defaultTranslations();
  }

  private defaultTranslations() {
    bitfToTranslate('BITF.LABEL.MONTH_1');
    bitfToTranslate('BITF.LABEL.MONTH_2');
    bitfToTranslate('BITF.LABEL.MONTH_3');
    bitfToTranslate('BITF.LABEL.MONTH_4');
    bitfToTranslate('BITF.LABEL.MONTH_5');
    bitfToTranslate('BITF.LABEL.MONTH_6');
    bitfToTranslate('BITF.LABEL.MONTH_7');
    bitfToTranslate('BITF.LABEL.MONTH_8');
    bitfToTranslate('BITF.LABEL.MONTH_9');
    bitfToTranslate('BITF.LABEL.MONTH_10');
    bitfToTranslate('BITF.LABEL.MONTH_11');
    bitfToTranslate('BITF.LABEL.MONTH_12');
  }

  initLogSender() {
    this.logDnaSenderService.init();
  }
}
