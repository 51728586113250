<div class="row py-3 mx-0">
    <div class="position-relative">
        <div class="circle d-flex align-items-center justify-content-center">
            {{qualitativeSkill?.question?.position + 1}}
        </div>
        <div class="border-right-black position-absolute w-50 h-100">
        </div>
    </div>
    <div class="ml-4 mt-3 w-75">
        <p>{{'COMMON.LABEL.QUESTION.LABEL' | translate}}
            {{qualitativeSkill?.question?.position + 1}}/{{skillsCount}}</p>
        <h1>{{qualitativeSkill?.question?.title}}</h1>
        <h3>
            <b>{{'COMMON.LABEL.DESCRIPTION' | translate}}:</b><br>{{qualitativeSkill?.question?.description}}
        </h3>
        <h3 class="pt-3"><b>{{'COMMON.LABEL.WEIGHT' | translate}}:</b>
            {{qualitativeSkill.weight}}/7</h3>
        <h3><b>{{'COMMON.LABEL.ASSESSMENT.LABEL' | translate}}:</b></h3>
        <mra-questionary-rating [score]="qualitativeSkill.score"
            [readOnly]="readOnly"
            (rate)="onRate($event)"></mra-questionary-rating>

        <mat-form-field class="w-100">
            <mat-label>{{'COMMON.LABEL.ADD_NOTE' | translate}}</mat-label>
            <textarea matInput
                [(ngModel)]="qualitativeSkill.notes"
                [readonly]="readOnly"
                rows="4"
                class="w-100"
                placeholder="{{'COMMON.LABEL.ADD_NOTE' | translate}}"></textarea>
        </mat-form-field>

        <div>
            <button mat-button
                (click)="onBack()"
                *ngIf="qualitativeSkill?.question?.position !== 0">
                {{'COMMON.LABEL.BACK' | translate}}
            </button>
            <button mat-button
                (click)="onNext()">
                {{'COMMON.LABEL.NEXT' | translate}}
            </button>
        </div>
    </div>
</div>