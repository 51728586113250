import {
  BitfMockDbPathBuilder,
  BitfMockDbRequestMapper,
  BitfMockDbResponseMapper,
  BitfMockDbEnvelopeMapper,
} from '@bitf/core/parsers/mock-db-parser';

import { AppRequestMapper, AppResponseMapper, AppPathBuilder, AppEnvelopeMapper } from '@common/parsers';
import { IBitfParserStrategy } from '@interfaces';

export const BITF_CONFIGS: any = {
  apiErrorsInterceptorService: { interceptHttpResponsesWithCode: [401, '4XX', '5XX'] },
  parsers: {
    defaultParser: 'appParser',
    parserStrategies: {
      appParser: {
        namespace: '@sintra',
        customHeaders: {
          Prefer: 'odata.include-annotations=*',
        },
        uiMessageKey: 'messages',
        pathBuilder: new AppPathBuilder(),
        requestMapper: new AppRequestMapper(),
        responseMapper: new AppResponseMapper(),
        envelopeMapper: new AppEnvelopeMapper(),
      } as IBitfParserStrategy,
      mockDbParser: {
        pathBuilder: new BitfMockDbPathBuilder(),
        requestMapper: new BitfMockDbRequestMapper(),
        responseMapper: new BitfMockDbResponseMapper(),
        envelopeMapper: new BitfMockDbEnvelopeMapper(),
      } as IBitfParserStrategy,
    },
  },
};
