<div>
    <table mat-table
        [dataSource]="dataSource"
        class="w-100 mt-3">

        <ng-container matColumnDef="id">
            <th mat-header-cell
                *matHeaderCellDef>
                <bitf-mat-sort [apiCallStateName]="apiCallStateName"
                    [apiRequestPartKey]="EApiRequestPartKeys.SORT_ASSESSMENTS_LIST_ID"
                    [option]="orderByOptions.id"></bitf-mat-sort>
            </th>
            <td mat-cell
                *matCellDef="let element"
                class="font-weight-bold">
                <div>[{{element.id}}]</div>
            </td>
        </ng-container>

        <ng-container matColumnDef="fullName">
            <th mat-header-cell
                *matHeaderCellDef>
                <bitf-mat-sort [apiCallStateName]="apiCallStateName"
                    [apiRequestPartKey]="EApiRequestPartKeys.SORT_ASSESSMENTS_LIST_NAME"
                    [option]="orderByOptions.fullName"></bitf-mat-sort>
            </th>
            <td mat-cell
                *matCellDef="let element"
                class="font-weight-bold">
                <a [routerLink]="userRegistryLink(element)">
                    {{element.fullName | titlecase}}
                </a>
            </td>
        </ng-container>

        <ng-container matColumnDef="subRole">
            <th mat-header-cell
                *matHeaderCellDef>
                <bitf-mat-sort [apiCallStateName]="apiCallStateName"
                    [apiRequestPartKey]="EApiRequestPartKeys.SORT_ASSESSMENTS_LIST_ROLE"
                    [option]="orderByOptions.subRole"></bitf-mat-sort>
            </th>
            <td mat-cell
                *matCellDef="let element">{{element.subRole}} </td>
        </ng-container>

        <ng-container matColumnDef="assessmentsCount">
            <th mat-header-cell
                *matHeaderCellDef
                class="px-2">
                <bitf-mat-sort [apiCallStateName]="apiCallStateName"
                    [apiRequestPartKey]="EApiRequestPartKeys.SORT_ASSESSMENTS_LIST_TOT"
                    [option]="orderByOptions.assessmentsCount"></bitf-mat-sort>
            </th>
            <td mat-cell
                *matCellDef="let element"
                class="px-2">{{element.assessmentsCount}} </td>
        </ng-container>

        <ng-container matColumnDef="lastAssessmentDate">
            <th mat-header-cell
                *matHeaderCellDef
                class="px-2">
                <bitf-mat-sort [apiCallStateName]="apiCallStateName"
                    [apiRequestPartKey]="EApiRequestPartKeys.SORT_ASSESSMENTS_LIST_LATEST_DATE"
                    [option]="orderByOptions.lastAssessmentDate"></bitf-mat-sort>
            </th>
            <td mat-cell
                *matCellDef="let element"
                class="px-2">
                <span
                    *ngIf="element.assessmentsCount > 0">{{element.lastAssessmentDate | date: 'dd/MM/yyyy'}}</span>
                <span *ngIf="element.assessmentsCount === 0">-</span>
            </td>
        </ng-container>

        <ng-container matColumnDef="lastAssessmentScore">
            <th mat-header-cell
                *matHeaderCellDef
                class="px-2">
                <bitf-mat-sort [apiCallStateName]="apiCallStateName"
                    [apiRequestPartKey]="EApiRequestPartKeys.SORT_ASSESSMENTS_LIST_LATEST_SCORE"
                    [option]="orderByOptions.lastAssessmentScore"></bitf-mat-sort>
            </th>
            <td mat-cell
                *matCellDef="let element"
                class="px-2">
                <span
                    *ngIf="element.assessmentsCount > 0">{{element.lastAssessmentScore | number:'1.1-1'}}</span>
                <span *ngIf="element.assessmentsCount === 0">-</span>
            </td>
        </ng-container>

        <ng-container matColumnDef="deleted">
            <th mat-header-cell
                *matHeaderCellDef
                class="px-2">
                <bitf-mat-sort [apiCallStateName]="apiCallStateName"
                    [apiRequestPartKey]="EApiRequestPartKeys.SORT_ASSESSMENTS_USER_DELETED"
                    [option]="orderByOptions.deleted"></bitf-mat-sort>
            </th>
            <td mat-cell
                *matCellDef="let element"
                class="px-2">
                <span *ngIf="element.deleted">{{'COMMON.LABEL.NOT_ACTIVE_M' | translate}}</span>          
                <span *ngIf="!element.deleted">{{'COMMON.LABEL.ACTIVE_M' | translate}}</span>                
            </td>
        </ng-container>

        <ng-container matColumnDef="actions">
            <th mat-header-cell
                *matHeaderCellDef> </th>
            <td mat-cell
                *matCellDef="let element">
                <div class="d-flex justify-content-end w-100">
                    <ng-container *ngIf="isAssessmentManagement && element.subRole && !element.deleted">
                        <button mat-flat-button
                            [disabled]="!actionButtonEnabled"
                            class="text-uppercase"
                            *ngIf="!element.isDraft"
                            (click)="onEvaluate(element.id)">
                            {{'COMMON.LABEL.NEW' | translate}}
                        </button>
                        <button mat-flat-button
                            [disabled]="!actionButtonEnabled"
                            class="text-uppercase"
                            *ngIf="element.isDraft"
                            (click)="onResume(element)">
                            {{'COMMON.ASSESSMENT.RESUME' | translate}}
                        </button>
                    </ng-container>
                    <ng-container *ngIf="!isAssessmentManagement">
                        <button mat-flat-button
                            [disabled]="!actionButtonEnabled || !element.subRole || element.assessmentsCount === 0"
                            (click)="onOpen(element)"
                            class="text-uppercase">
                            {{'COMMON.LABEL.VIEW' | translate}}
                        </button>
                    </ng-container>
                </div>
            </td>
        </ng-container>

        <tr mat-header-row
            *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row
            class="highlight-row"
            *matRowDef="let row; columns: displayedColumns;"></tr>

    </table>

    <bitf-mat-paginator *ngIf="pagination"
        [apiCallStateName]="apiCallStateName"
        [pagination]="pagination"
        [pageIndexOffset]="1"
        [pageSizeOptions]="pageSizeOptions">
    </bitf-mat-paginator>

</div>