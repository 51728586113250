import { Injectable, Injector } from '@angular/core';
import { Observable, of } from 'rxjs';
import { BitfApiService } from '@bitf/services/api/bitf-api.service';
import { IBitfApiResponse } from '@interfaces';
import { User } from '@models';
import { Roles } from '@web/enums';
import { BitfFormItemConfig } from '@common/libs/bitforce/core/models';
import { TranslateService } from '@ngx-translate/core';
import { ONE_DAY_MS } from '@common/libs/bitforce/utils/bitf-dates.utils';

@Injectable({
  providedIn: 'root',
})
export class UsersService extends BitfApiService {
  constructor(public injector: Injector, private translateService: TranslateService) {
    // super('users', injector, environment.mockApiUrl);
    super('users', injector);
  }

  getMe(): Observable<IBitfApiResponse<User>> {
    return super.request<User>({
      path: '/me',
      method: 'GET',
      disableHideLoader: true,
    });
  }

  getFormItemsConfig(): Observable<BitfFormItemConfig> {
    return of(
      new BitfFormItemConfig({
        id: {
          metadata: {
            filter: {
              expression: 'id eq {{keyword}}',
              conjunction: 'and',
            },
          },
          label: this.translateService.instant('COMMON.LABEL.ID'),
          isEditable: true,
          isVisible: true,
          isRequired: false,
          setDefault: false,
          validators: [
            {
              key: 'maxLength',
              params: [32],
            },
          ],
          tooltip: this.translateService.instant('COMMON.LABEL.ID'),
          type: 'text',
          default: null,
        },
        fullName: {
          metadata: {
            filter: {
              expression: `contains(tolower(fullName), tolower('{{keyword}}'))`,
              conjunction: 'and',
            },
          },
          label: this.translateService.instant('COMMON.LABEL.NAME_AND_SURNAME'),
          isEditable: true,
          isVisible: true,
          isRequired: false,
          setDefault: false,
          validators: [
            {
              key: 'maxLength',
              params: [32],
            },
          ],
          tooltip: this.translateService.instant('COMMON.LABEL.NAME_AND_SURNAME'),
          type: 'text',
          default: null,
        },
        role: {
          metadata: {
            filter: {
              expression: 'subRoleId eq {{keyword}}',
              conjunction: 'and',
            },
          },
          options: Object.keys(Roles)
            .filter(key => !isNaN(Number(Roles[key])))
            .map(role => {
              return {
                id: Roles[role],
                label: this.translateService.instant('SUB_ROLES.' + role),
                metadata: null,
              };
            }),
          type: 'select',
          label: this.translateService.instant('COMMON.LABEL.ROLE'),
          isEditable: true,
          isVisible: true,
          isRequired: false,
          setDefault: true,
          validators: null,
          tooltip: this.translateService.instant('COMMON.LABEL.ROLE'),
        },
        hireDateFrom: {
          metadata: {
            filter: {
              expression: 'registrationDate gt {{keyword}}',
              conjunction: 'and',
            },
          },
          label: this.translateService.instant('COMMON.LABEL.FROM'),
          isEditable: true,
          isVisible: true,
          isRequired: false,
          setDefault: false,
          tooltip: this.translateService.instant('COMMON.LABEL.FROM'),
          type: 'date',
          default: null,
        },
        hireDateTo: {
          metadata: {
            filter: {
              expression: 'registrationDate lt {{keyword}}',
              conjunction: 'and',
            },
            hourOfTheDayMs: ONE_DAY_MS - 1,
          },
          label: this.translateService.instant('COMMON.LABEL.TO'),
          isEditable: true,
          isVisible: true,
          isRequired: false,
          setDefault: false,
          tooltip: this.translateService.instant('COMMON.LABEL.TO'),
          type: 'date',
          default: null,
        },
      })
    );
  }
}
