import { EventEmitter } from '@angular/core';
import {
  FormControl,
  ValidatorFn,
  AbstractControlOptions,
  AsyncValidatorFn,
  Validators,
} from '@angular/forms';

import { merge, timer } from 'rxjs';
import { debounce, tap } from 'rxjs/operators';

import { bitfIsStrictObject } from '@bitf/utils/bitf-objects.utils';
import { BitfTryCatch } from '../decorators/bitf-try-catch.decorator';
import { IBitfFormControlValidator } from './bitf-form-control-validator.interface';
import { EBitfFormControlValidatorsKeys } from './bitf-form-control-validators.enum';

import { IBitfFormControlTranslations } from '@interfaces';
import { CONSTANTS } from '@constants';
import { bitfValidateEmail } from '../../validators/bitf-email.validator';

export class BitfFormControl extends FormControl {
  // NOTE: here are standard formControl validators
  private staticValidators: ValidatorFn[] = [];
  // NOTE: validators which we can control - add / remove
  private dynamicValidators: IBitfFormControlValidator[] = [];

  private validationMessages: any = { ...CONSTANTS.VALIDATION_MESSAGES };

  // validation
  isInvalid = false;
  validationErrors: IBitfFormControlTranslations;

  constructor(
    formState: any = null,
    validatorOrOpts?: ValidatorFn | ValidatorFn[] | AbstractControlOptions | null,
    asyncValidator?: AsyncValidatorFn | AsyncValidatorFn[] | null
  ) {
    super(formState, validatorOrOpts, asyncValidator);
    this.setStaticValidators(validatorOrOpts);
    this.initValidation();
  }

  // NOTE: This function will be called from bitf form item component to add validators that comes from
  // formItemConfig validators. This can also be called programmatically to add / remove validators at runtime
  // based on some conditions
  setDynamicValidator(formItemValidator: IBitfFormControlValidator | Array<IBitfFormControlValidator>) {
    if (Array.isArray(formItemValidator)) {
      formItemValidator.forEach(dynamicValidatorItem => {
        this.addDynamicValidator(dynamicValidatorItem);
      });
    } else {
      this.addDynamicValidator(formItemValidator);
    }

    this.applyValidators();
  }

  // NOTE: We can remove only validators added from formItemConfig (which is calling setDynamicValidator)
  // not via formBuilder in the constructor because they don't have the key
  removeDynamicValidator(key: EBitfFormControlValidatorsKeys) {
    this.dynamicValidators = this.dynamicValidators.filter(dynamicValidator => dynamicValidator.key !== key);
    this.applyValidators();
  }

  addStaticValidator(validator: ValidatorFn) {
    this.staticValidators.push(validator);
    this.applyValidators();
  }

  // NOTE: this will be used to remove validators added when the formControl is created via form builder
  // we can u se only his position index in the array because there is no key available for those validators
  removeStaticValidator(validatorIndex: number) {
    if (this.staticValidators.length <= validatorIndex) {
      return;
    }
    this.staticValidators.splice(validatorIndex, 1);
    this.applyValidators();
  }

  markAsDirty() {
    const wasDirty = this.dirty;
    super.markAsDirty();
    if (!wasDirty) {
      (this.statusChanges as EventEmitter<any>).emit(this.status);
    }
  }

  markAsTouched() {
    const wasTouched = this.touched;
    super.markAsTouched();
    if (!wasTouched) {
      (this.statusChanges as EventEmitter<any>).emit(this.status);
    }
  }

  initValidation() {
    merge(this.statusChanges, this.valueChanges)
      .pipe(
        debounce(() => timer(100)),
        tap(() => setTimeout(() => this.validate()))
      )
      .subscribe();
  }

  @BitfTryCatch()
  validate() {
    this.isInvalid = this.invalid && this.touched && !this.pristine;

    if (!this.isInvalid) {
      this.validationErrors = undefined;
      return;
    }

    const keys: Array<string> = [];
    const params: any = {};

    Object.keys(this.errors).forEach(key => {
      if (!this.validationMessages[key]) {
        throw new Error(
          `Translation for validator ${key} is missing, add it in COMMON/CONSTATS or WEB/CONSTANTS`
        );
      }

      keys.push(this.validationMessages[key]);
      Object.assign(params, this.errors[key]);
    });

    this.validationErrors = {
      keys,
      params,
    };
  }

  private setStaticValidators(
    validatorOrOpts?: ValidatorFn | ValidatorFn[] | AbstractControlOptions | null
  ): void {
    const validator = (bitfIsStrictObject(validatorOrOpts) &&
    (validatorOrOpts as AbstractControlOptions).validators
      ? (validatorOrOpts as AbstractControlOptions).validators
      : validatorOrOpts) as ValidatorFn | ValidatorFn[] | null;

    if (!validator) {
      return;
    }

    this.staticValidators = Array.isArray(validator) ? validator : [validator] || [];
  }

  private addDynamicValidator(newDynamicValidator: IBitfFormControlValidator) {
    const dynamicValidatorIndex = this.dynamicValidators.findIndex(
      dynamicValidator => dynamicValidator.key === newDynamicValidator.key
    );

    if (dynamicValidatorIndex !== -1) {
      this.dynamicValidators[dynamicValidatorIndex] = newDynamicValidator;
    } else {
      this.dynamicValidators.push(newDynamicValidator);
    }

    // NOTE: this should not happend since the BE can't send customValidato functions, left here as reference
    // if (newDynamicValidator.customValidationMessage) {
    //   this.addCustomValidationMesasge({
    //     [newDynamicValidator.key]: newDynamicValidator.customValidationMessage,
    //   });
    // }
  }

  // NOTE: this should not happend since the BE can't send customValidato functions, left here as reference
  // private addCustomValidationMesasge(customValidaionMessage: { [key: string]: string }) {
  //   Object.assign(this.validationMessages, customValidaionMessage);
  // }

  private applyValidators() {
    const validators: Array<ValidatorFn> = [...this.staticValidators, ...this.proccessDynamicValidators()];
    this.setValidators(validators);
    this.updateValueAndValidity();
  }

  private proccessDynamicValidators(): Array<ValidatorFn> {
    if (!this.dynamicValidators.length) {
      return [];
    }

    return this.dynamicValidators.map((dynamicValidator: IBitfFormControlValidator) => {
      const { key, params, customValidator } = dynamicValidator;

      return customValidator
        ? this.getValidatorFn(customValidator, params)
        : this.getAngularValidator(key, params);
    });
  }

  private getValidatorFn(validationFunction, params = null): ValidatorFn {
    return (params ? validationFunction(...params) : validationFunction) as ValidatorFn;
  }

  @BitfTryCatch()
  private getAngularValidator(validatorKey: EBitfFormControlValidatorsKeys, params: any): ValidatorFn {
    try {
      params = Array.isArray(params) ? params : params ? [params] : undefined;

      if (validatorKey === EBitfFormControlValidatorsKeys.email) {
        return bitfValidateEmail as ValidatorFn;
      }

      return this.getValidatorFn(Validators[validatorKey], params);
    } catch (e) {
      throw Error(`Incorrect validatorKey, function ${validatorKey} doesnt exist in Angular Validators`);
    }
  }
}
