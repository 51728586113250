import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { DesktopLayoutComponent } from './core/components/desktop-layout/desktop-layout.component';
import { NotFoundComponent } from './core/components/not-found/not-found.component';
import { AuthGuard } from '@common/core/services/guards';
import { EUserProfile } from '@models';

const routes: Routes = [
  { path: '', redirectTo: '/sign-in', pathMatch: 'full' },
  {
    path: '',
    component: DesktopLayoutComponent,
    children: [
      { path: 'sign-in', loadChildren: () => import('./modules/auth/auth.module').then(m => m.AuthModule) },
      {
        path: 'admin',
        loadChildren: () => import('./modules/admin/admin.module').then(m => m.AdminModule),
        canActivate: [AuthGuard],
        data: { eProfile: EUserProfile.ADMIN },
      },
      {
        path: 'owner',
        loadChildren: () => import('./modules/owner/owner.module').then(m => m.OwnerModule),
        canActivate: [AuthGuard],
        data: { eProfile: EUserProfile.OWNER },
      },
      {
        path: 'supervisor',
        loadChildren: () => import('./modules/supervisor/supervisor.module').then(m => m.SupervisorModule),
        canActivate: [AuthGuard],
        data: { eProfile: EUserProfile.SUPERVISOR },
      },
      {
        path: 'collaborator',
        loadChildren: () =>
          import('./modules/collaborator/collaborator.module').then(m => m.CollaboratorModule),
        canActivate: [AuthGuard],
        data: { eProfile: EUserProfile.COLLABORATOR },
      },
    ],
  },
  {
    path: 'changelog',
    loadChildren: () =>
      import('./../../common/libs/bitforce/modules/changelog/bitf-changelog.module').then(
        m => m.BitfChangelogModule
      ),
  },
  {
    path: '**',
    component: NotFoundComponent,
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
