import { Injectable, Injector } from '@angular/core';

import { BitfApiService } from '@bitf/services/api/bitf-api.service';
import { Survey } from '@web/core/models';
import { IBitfApiResponse } from '@web/interfaces';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class SurveysService extends BitfApiService {
  constructor(public injector: Injector) {
    // super('surveys', injector, environment.mockApiUrl);
    super('surveys', injector);
  }

  getDraft(): Observable<Survey> {
    return super
      .get<Survey>({
        path: `?$filter=status eq 'DRAFT'&$expand=questions($expand=weights)`,
      })
      .pipe(map((result: IBitfApiResponse<Survey[]>) => result.content[0]));
  }

  getActive(): Observable<Survey> {
    return super
      .get<Survey>({
        path: `?$filter=status eq 'ACTIVE'&$expand=questions($expand=weights)`,
      })
      .pipe(map((result: IBitfApiResponse<Survey[]>) => result.content[0]));
  }

  hasActiveSurvey(): Observable<boolean> {
    return super
      .get<Survey>({
        path: `?$filter=status eq 'ACTIVE'&$count=true&$top=1`,
      })
      .pipe(
        map(response => {
          return response.originalBody['@odata.count'] >= 1;
        })
      );
  }
}
