import { EBitfStoreActions } from '@common/enums';
import { bitfToTranslate } from '@common/libs/bitforce/utils/bitf-translate.utils';
export enum EWebStoreActions {
  SET_PHARMACY = 'SET_PHARMACY',
}

export enum Roles {
  PHARMACIST_COLLABORATOR = 1,
  DERMOCOSMETICS_SPECIALIST = 2,
  WAREHOUSE_WORKER = 3,
}

// Needed to keep roles translations when running extract translations
bitfToTranslate('SUB_ROLES.PHARMACIST_COLLABORATOR');
bitfToTranslate('SUB_ROLES.DERMOCOSMETICS_SPECIALIST');
bitfToTranslate('SUB_ROLES.WAREHOUSE_WORKER');

export const eStoreActions = { ...EBitfStoreActions, ...EWebStoreActions, ...Roles };
