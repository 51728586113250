import { Component, OnInit, Input, ViewChild, OnChanges, OnDestroy } from '@angular/core';
import { ChartComponent } from 'angular2-chartjs';
import { CONSTANTS } from '@web/constants';
import { TranslateService } from '@ngx-translate/core';
import { IQualitativeSkillsChart } from '@web/interfaces';

@Component({
  selector: 'mra-radar-chart',
  templateUrl: './radar-chart.component.html',
  styleUrls: ['./radar-chart.component.scss'],
})
export class RadarChartComponent implements OnInit, OnChanges, OnDestroy {
  @Input() data: IQualitativeSkillsChart[];
  @Input() large = false;
  printEvent: MediaQueryList;
  private beforePrintHandler: (any) => void;

  @ViewChild('chartComponent', { static: false }) chartComponent: ChartComponent;

  colors = [CONSTANTS.COLOR_GREEN, CONSTANTS.COLOR_ORANGE];
  radarChart = {
    type: 'radar',
    data: {
      labels: [],
      datasets: [],
    },
    options: {
      responsive: true,
      maintainAspectRatio: true,
      scale: {
        ticks: {
          beginAtZero: true,
          max: 7,
          stepSize: 1,
        },
      },
      tooltips: {
        callbacks: {
          label: (tooltipItem, data) => {
            let label = data.labels[tooltipItem.index] || '';
            if (label) {
              label += ': ';
            }
            label += tooltipItem.value;
            return label;
          },
        },
      },
    },
  };

  constructor(private translateService: TranslateService) {
    this.beforePrintHandler = mql => {
      if (mql.matches) {
        this.chartComponent.chart.resize();
      }
    };
    this.printEvent = window.matchMedia('print');
  }

  ngOnInit(): void {
    this.printEvent.addEventListener('change', this.beforePrintHandler);
  }

  ngOnChanges() {
    this.updateChart();
  }

  ngOnDestroy() {
    this.printEvent.removeEventListener('change', this.beforePrintHandler);
  }

  private updateChart() {
    this.radarChart.data.datasets = [];
    this.radarChart.data.labels = [];
    if (!this.data) {
      this.chartComponent?.chart?.update();
      return;
    }
    for (const level of this.data) {
      const colorIndex = this.radarChart.data.datasets.length;
      for (const skill of level.qualitativeSkills) {
        const index = this.radarChart.data.datasets.findIndex(set => set.label === level.label);
        if (colorIndex === 0) {
          this.radarChart.data.labels.push(skill.question.title);
        }
        if (index === -1) {
          this.radarChart.data.datasets.push({
            label: level.label,
            data: [skill.score],
            backgroundColor: [CONSTANTS.COLOR_TRANSPARENT],
            borderColor: [this.colors[colorIndex]],
          });
        } else {
          this.radarChart.data.datasets[index].data.push(skill.score);
          this.radarChart.data.datasets[index].borderColor.push(this.colors[colorIndex]);
        }
      }
    }
    this.chartComponent?.chart?.update();
  }
}
