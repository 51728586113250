import { EApiCallStateNames, EApiRequestPartKeys, EBitfApiSortDirection } from '@enums';
import { IBitfApiCallState, IBitfODataSearchSettings } from '@interfaces';
import { DefaultApiCallStateMapper } from './default-api-call-state.mapper';
// tslint:disable:max-line-length
import { BitfSortApiRequestPart } from '@common/libs/bitforce/core/api-call-state/bitf-sort-api-request-part.builder';
import { BitfOdataSearchApiRequestPart } from '@common/libs/bitforce/core/api-call-state/bitf-odata-api-call-state/bitf-odata-search-api-request-part.builder';
import { BitfODataFormItemConfigApiRequestPart } from '@common/libs/bitforce/core/api-call-state/bitf-odata-api-call-state/bitf-odata-form-item-config-api-request-part.builder';
import { BitfPaginationApiRequestPart } from '@bitf/core/api-call-state/bitf-pagination-api-request-part';
// tslint:enable:max-line-length

const CONTAINS_STRING = (field: string) => `contains(tolower(${field}), '{{keyword}}')`;
const CONTAINS_CODE = (field: string) => `contains(cast(${field}, 'Edm.String'), '{{keyword}}')`;

export const apiCallStateConfig = [
  {
    apiCallStateName: EApiCallStateNames.ADMIN_PHARMACIES_LIST,
    apiRequest: {},
    requestParts: [
      new BitfPaginationApiRequestPart(EApiRequestPartKeys.PAGINATION, { page: 1, size: 10 }),
      new BitfODataFormItemConfigApiRequestPart(),
      new BitfOdataSearchApiRequestPart({
        defaultPartData: {
          data: {
            searchExpression: `${CONTAINS_STRING('name')} or ${CONTAINS_CODE('code')} or ${CONTAINS_CODE(
              'pdcCode'
            )} or ${CONTAINS_STRING('kamName')} or ${CONTAINS_STRING('tdfName')}`,
            searchForEachWord: false,
          } as IBitfODataSearchSettings,
        },
      }),
      new BitfSortApiRequestPart({
        key: EApiRequestPartKeys.SORT_PHARMACIES_LIST_NAME,
        defaultPartData: {
          data: { property: 'name', direction: EBitfApiSortDirection.UNDEFINED },
        },
      }),
      new BitfSortApiRequestPart({
        key: EApiRequestPartKeys.SORT_PHARMACIES_LIST_PDC_CODE,
        defaultPartData: {
          data: { property: 'pdcCode', direction: EBitfApiSortDirection.UNDEFINED },
        },
      }),
      new BitfSortApiRequestPart({
        key: EApiRequestPartKeys.SORT_PHARMACIES_LIST_KAM_CODE,
        defaultPartData: {
          data: { property: 'kamName', direction: EBitfApiSortDirection.UNDEFINED },
        },
      }),
      new BitfSortApiRequestPart({
        key: EApiRequestPartKeys.SORT_PHARMACIES_LIST_TDF_NAME,
        defaultPartData: {
          data: { property: 'tdfName', direction: EBitfApiSortDirection.UNDEFINED },
        },
      }),
      new BitfSortApiRequestPart({
        key: EApiRequestPartKeys.SORT_PHARMACIES_LIST_EMP_COUNT,
        defaultPartData: {
          data: { property: 'assessedEmployeesCount', direction: EBitfApiSortDirection.UNDEFINED },
        },
      }),
      new BitfSortApiRequestPart({
        key: EApiRequestPartKeys.SORT_PHARMACIES_LIST_ASSESSMENTS_COUNT,
        defaultPartData: {
          data: { property: 'assessmentsCount', direction: EBitfApiSortDirection.UNDEFINED },
        },
      }),
      new BitfSortApiRequestPart({
        key: EApiRequestPartKeys.SORT_PHARMACIES_LIST_LATEST_ASSESSMENT_DATE,
        defaultPartData: {
          data: { property: 'latestAssessmentDate', direction: EBitfApiSortDirection.UNDEFINED },
        },
      }),
      new BitfSortApiRequestPart({
        key: EApiRequestPartKeys.SORT_PHARMACIES_LIST_ACTIVE,
        defaultPartData: {
          data: { property: 'isActive', direction: EBitfApiSortDirection.UNDEFINED },
        },
      }),
    ],
    apiCallStateMapper: new DefaultApiCallStateMapper(),
  } as IBitfApiCallState,
  {
    apiCallStateName: EApiCallStateNames.HISTORICAL_ASSESSMENTS,
    apiRequest: {
      embed: ['user'],
    },
    requestParts: [
      new BitfPaginationApiRequestPart(EApiRequestPartKeys.PAGINATION, { page: 1, size: 10 }),
      new BitfODataFormItemConfigApiRequestPart(),
      new BitfOdataSearchApiRequestPart({
        defaultPartData: {
          data: {
            searchExpression: `${CONTAINS_STRING('user/fullName')} or ${CONTAINS_STRING(
              'user/subRole'
            )} or ${CONTAINS_CODE('user/id')}`,
            searchForEachWord: false,
          } as IBitfODataSearchSettings,
        },
      }),
      new BitfSortApiRequestPart({
        key: EApiRequestPartKeys.SORT_HISTORICAL_ASSESSMENTS_ID,
        defaultPartData: {
          data: { property: 'id', direction: EBitfApiSortDirection.UNDEFINED },
        },
      }),
      new BitfSortApiRequestPart({
        key: EApiRequestPartKeys.SORT_HISTORICAL_ASSESSMENTS_NAME,
        defaultPartData: {
          data: { property: 'user/fullName', direction: EBitfApiSortDirection.UNDEFINED },
        },
      }),
      new BitfSortApiRequestPart({
        key: EApiRequestPartKeys.SORT_HISTORICAL_ASSESSMENTS_ROLE,
        defaultPartData: {
          data: { property: 'user/role', direction: EBitfApiSortDirection.UNDEFINED },
        },
      }),
      new BitfSortApiRequestPart({
        key: EApiRequestPartKeys.SORT_HISTORICAL_ASSESSMENTS_DATE,
        defaultPartData: {
          data: { property: 'assessmentDate', direction: EBitfApiSortDirection.DESC },
        },
      }),
      new BitfSortApiRequestPart({
        key: EApiRequestPartKeys.SORT_HISTORICAL_ASSESSMENTS_START_DATE,
        defaultPartData: {
          data: { property: 'startDate', direction: EBitfApiSortDirection.UNDEFINED },
        },
      }),
      new BitfSortApiRequestPart({
        key: EApiRequestPartKeys.SORT_HISTORICAL_ASSESSMENTS_END_DATE,
        defaultPartData: {
          data: { property: 'endDate', direction: EBitfApiSortDirection.UNDEFINED },
        },
      }),
      new BitfSortApiRequestPart({
        key: EApiRequestPartKeys.SORT_HISTORICAL_SHARED_WITH_USER,
        defaultPartData: {
          data: { property: 'sharedWithUser', direction: EBitfApiSortDirection.UNDEFINED },
        },
      }),
    ],
    apiCallStateMapper: new DefaultApiCallStateMapper(),
  } as IBitfApiCallState,
  {
    apiCallStateName: EApiCallStateNames.GROUPED_ASSESSMENTS,
    apiRequest: {},
    requestParts: [
      new BitfPaginationApiRequestPart(EApiRequestPartKeys.PAGINATION, { page: 1, size: 10 }),
      new BitfODataFormItemConfigApiRequestPart(),
      new BitfOdataSearchApiRequestPart({
        defaultPartData: {
          data: {
            searchExpression: `${CONTAINS_STRING('fullName')} or ${CONTAINS_STRING(
              'subRole'
            )} or ${CONTAINS_CODE('id')}`,
            searchForEachWord: false,
          } as IBitfODataSearchSettings,
        },
      }),
      new BitfSortApiRequestPart({
        key: EApiRequestPartKeys.SORT_ASSESSMENTS_LIST_ID,
        defaultPartData: {
          data: { property: 'id', direction: EBitfApiSortDirection.UNDEFINED },
        },
      }),
      new BitfSortApiRequestPart({
        key: EApiRequestPartKeys.SORT_ASSESSMENTS_LIST_NAME,
        defaultPartData: {
          data: { property: 'fullName', direction: EBitfApiSortDirection.UNDEFINED },
        },
      }),
      new BitfSortApiRequestPart({
        key: EApiRequestPartKeys.SORT_ASSESSMENTS_LIST_ROLE,
        defaultPartData: {
          data: { property: 'subRole', direction: EBitfApiSortDirection.UNDEFINED },
        },
      }),
      new BitfSortApiRequestPart({
        key: EApiRequestPartKeys.SORT_ASSESSMENTS_LIST_TOT,
        defaultPartData: {
          data: { property: 'assessmentsCount', direction: EBitfApiSortDirection.UNDEFINED },
        },
      }),
      new BitfSortApiRequestPart({
        key: EApiRequestPartKeys.SORT_ASSESSMENTS_LIST_LATEST_DATE,
        defaultPartData: {
          data: { property: 'lastAssessmentDate', direction: EBitfApiSortDirection.UNDEFINED },
        },
      }),
      new BitfSortApiRequestPart({
        key: EApiRequestPartKeys.SORT_ASSESSMENTS_LIST_LATEST_SCORE,
        defaultPartData: {
          data: { property: 'lastAssessmentScore', direction: EBitfApiSortDirection.UNDEFINED },
        },
      }),
      new BitfSortApiRequestPart({
        key: EApiRequestPartKeys.SORT_ASSESSMENTS_USER_DELETED,
        defaultPartData: {
          data: { property: 'deleted', direction: EBitfApiSortDirection.UNDEFINED },
        },
      }),
    ],
    apiCallStateMapper: new DefaultApiCallStateMapper(),
  } as IBitfApiCallState,
  {
    apiCallStateName: EApiCallStateNames.REGISTRIES,
    apiRequest: {},
    requestParts: [
      new BitfPaginationApiRequestPart(EApiRequestPartKeys.PAGINATION, { page: 1, size: 10 }),
      new BitfODataFormItemConfigApiRequestPart(),
      new BitfOdataSearchApiRequestPart({
        defaultPartData: {
          data: {
            searchExpression: `${CONTAINS_STRING('fullName')} or ${CONTAINS_STRING(
              'subRole'
            )} or ${CONTAINS_CODE('id')}`,
            searchForEachWord: false,
          } as IBitfODataSearchSettings,
        },
      }),
      new BitfSortApiRequestPart({
        key: EApiRequestPartKeys.SORT_REGISTRIES_ID,
        defaultPartData: {
          data: { property: 'id', direction: EBitfApiSortDirection.UNDEFINED },
        },
      }),
      new BitfSortApiRequestPart({
        key: EApiRequestPartKeys.SORT_REGISTRIES_NAME,
        defaultPartData: {
          data: { property: 'fullName', direction: EBitfApiSortDirection.UNDEFINED },
        },
      }),
      new BitfSortApiRequestPart({
        key: EApiRequestPartKeys.SORT_REGISTRIES_ROLE,
        defaultPartData: {
          data: { property: 'role', direction: EBitfApiSortDirection.UNDEFINED },
        },
      }),
      new BitfSortApiRequestPart({
        key: EApiRequestPartKeys.SORT_REGISTRIES_REGISTRATION_DATE,
        defaultPartData: {
          data: { property: 'registrationDate', direction: EBitfApiSortDirection.UNDEFINED },
        },
      }),
      new BitfSortApiRequestPart({
        key: EApiRequestPartKeys.SORT_REGISTRIES_BIRTH_DATE,
        defaultPartData: {
          data: { property: 'birthDate', direction: EBitfApiSortDirection.UNDEFINED },
        },
      }),
      new BitfSortApiRequestPart({
        key: EApiRequestPartKeys.SORT_REGISTRIES_DELETED,
        defaultPartData: {
          data: { property: 'deleted', direction: EBitfApiSortDirection.UNDEFINED },
        },
      }),
    ],
    apiCallStateMapper: new DefaultApiCallStateMapper(),
  } as IBitfApiCallState,
];
