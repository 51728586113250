<mat-form-field *ngIf="isDropDown()"
  color="primary"
  class="mt-3"
  appearance="outline">
  <mat-label>{{ 'COMMON.LABEL.ORDER_BY' | translate}}</mat-label>
  <mat-select placeholder="{{ 'COMMON.LABEL.ORDER_BY' | translate}}"
    (selectionChange)="onSelectionChange($event)"
    [formControl]="formControl">
    <mat-option [value]="option.value"
      *ngFor="let option of options">
      {{option.label}}
    </mat-option>
  </mat-select>
</mat-form-field>

<ng-container *ngIf="!isDropDown()">
  <button mat-button
    class="p-1"
    (click)="sort(option)">
    {{option.label}}
    <mat-icon *ngIf="option.value.direction === EBitfApiSortDirection.ASC">arrow_upward</mat-icon>
    <mat-icon *ngIf="option.value.direction === EBitfApiSortDirection.DESC">arrow_downward</mat-icon>
  </button>
</ng-container>