import { SuperModel } from './super-model.model';

export class YieldData extends SuperModel {
  aPlusC: number;
  p: number;
  result: string;

  constructor(data = {}) {
    super(data);
  }

  get serialised() {
    return {
      aPlusC: this.aPlusC,
      p: this.p,
      result: this.result,
    };
  }
}
