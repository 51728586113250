import { BitfApiRequestPart } from '@bitf/core/api-call-state/bitf-api-request-part';
import { EApiRequestPartKeys, EBitfApiRequestPartsNames } from '@enums';
import { IBitfPartData } from '../bitf-api-call-state.interfaces';

export class BitfOdataSearchApiRequestPart extends BitfApiRequestPart {
  constructor({
    key,
    partName,
    defaultPartData,
  }: {
    key?: EApiRequestPartKeys;
    partName?: EBitfApiRequestPartsNames;
    defaultPartData?: IBitfPartData;
  } = {}) {
    if (!key) {
      key = EApiRequestPartKeys.SEARCH;
    }
    if (!partName) {
      partName = EBitfApiRequestPartsNames.FILTER;
    }

    super(key, partName, defaultPartData);
  }

  build(): void {
    this.part = undefined;

    const keyword = this.formValue?.keyword;
    if (!keyword) {
      return;
    }

    if (!this.data.searchForEachWord) {
      this.part = this.getSearchFilterForKeyword(keyword, this.data.searchExpression);
    } else {
      this.part = `(
        ${keyword
          .split(' ')
          .map(keywordStr => this.getSearchFilterForKeyword(keywordStr, this.data.searchExpression))
          .join(' or ')}
      )`;
    }
  }

  getSearchFilterForKeyword(keyword: string, searchExpression: string) {
    return `(${searchExpression.replace(new RegExp('{{keyword}}', 'g'), keyword.toLowerCase())})`;
  }
}
